/**
 * Generated by orval v6.9.6 🍺
 * Do not edit manually.
 * GiveCard APIs
 * GiveCard API Specification
 * OpenAPI spec version: 1.0.0
 */
import {
  useQuery,
  useMutation
} from 'vue-query'
import type {
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'vue-query/types'
import type {
  UseQueryReturnType
} from 'vue-query/lib/vue/useBaseQuery'
import type {
  GetAutoloadProfilesResponseResponse,
  WhimsyErrorResponseResponse,
  GetAutoloadProfilesParams,
  CreateAutoloadProfileResponseResponse,
  CreateAutoloadProfileParams,
  GetAutoloadProfileResponseResponse,
  ModifyAutoloadProfileResponseResponse,
  ModifyAutoloadProfileParams,
  AddCardsToAutoloadResponseResponse,
  AddCardsToAutoloadParams,
  EmptyResponseResponse,
  DeleteCardsFromAutoloadParams,
  CreateBankTransferRecipientResponseResponse,
  CreateBankTransferRecipientParams,
  UpdateRecipientResponseResponse,
  UpdateRecipientParams,
  CreateRecipientWithBankAccountResponseResponse,
  CreateRecipientWithBankAccountParams,
  GetBankTransferRecipientsResponseResponse,
  GetBankTransferRecipientsParams,
  GetBankTransferRecipientResponseResponse,
  GetRecipientActivitiesResponseBody,
  AddBankAccountToRecipientResponseResponse,
  AddBankAccountToRecipientParams,
  GetBankTransfersForRecipientResponseResponse,
  GetBankTransfersResponseResponse,
  GetBankTransfersParams,
  InitiateBankAccountTransfersResponseResponse,
  InitiateBankAccountTransfersParams,
  GetBankTransferResponseResponse,
  GetMerchantRuleResponseResponse,
  UpdateMerchantRuleResponseResponse,
  UpdateMerchantRuleParams,
  AddMetadataResponseResponse,
  AddMetadataParams,
  DeleteMetadataResponseResponse,
  DeleteMetadataParams,
  UpdateMetadataResponseResponse,
  UpdateMetadataParams,
  GetWebhooksPortalAccessLinkResponseResponse,
  GetBusinessAddressResponseResponse,
  GenerateApiKeyResponseResponse,
  GetBusinessCardIdsResponseResponse,
  GetBusinessCardIdsParams,
  GetOrderCardHistoryResponseResponse,
  GetOrderCardHistoryParams,
  GetBusinessCardOrdersResponseResponse,
  GetBusinessCardOrdersParams,
  GetIndividualCardOrderResponseResponse,
  TransferFundsBetweenFundingAccountsResponseResponse,
  TransferFundsBetweenFundingAccountsParams,
  GetFundingAccountDepositsResponseResponse,
  GetFundingAccountDepositsParams,
  GetBusinessFundingAccountsResponseResponse,
  InviteUsersResponseResponse,
  InviteUsersParams,
  GetBusinessLoadHistoryResponseResponse,
  GetBusinessLoadHistoryParams,
  CreateMerchantRuleResponseResponse,
  CreateMerchantRuleParams,
  GetBusinessMerchantRulesResponseResponse,
  GetBusinessPrefundingAchDetailsResponseResponse,
  GetBusinessPrefundingAchDetailsParams,
  CreateBusinessProgramResponseResponse,
  CreateBusinessProgramParamsBody,
  GetBusinessProgramsResponseResponse,
  GetBusinessBankTransferMetricsResponseResponse,
  GetBusinessBankTransferMetricsParams,
  GetBusinessCardMetricsResponseResponse,
  GetBusinessCardMetricsParams,
  GetBusinessTransactionCategoriesResponseResponse,
  GetBusinessTransactionCategoriesParams,
  GetTagNamesForBusinessResponseResponse,
  GetTagValuesByTagNameResponseResponse,
  GetTagValuesByTagNameParams,
  GetBusinessTagsResponseResponse,
  GetBusinessTransactionsResponseResponse,
  GetBusinessTransactionsParams,
  GetUsersForBusinessResponseResponse,
  CreateCardholderAndLinkCardResponseResponse,
  CreateCardholderAndLinkCardParams,
  CreateCardholderResponseResponse,
  CreateCardholderParams,
  SendResetCardholderPasswordEmailParams,
  GetCardholderResponseResponse,
  ActivateVirtualCardResponseResponse,
  ActivateVirtualCardParams,
  LinkCardholderToCardResponseResponse,
  LinkCardholderToCardParams,
  GetCardholderCommunicationPreferencesResponseResponse,
  UpdateCardholderCommunicationPreferencesResponseResponse,
  UpdateCardholderCommunicationPreferencesParams,
  CardToCardTransferResponseResponse,
  CardToCardTransferParams,
  GetCardsResponseResponse,
  GetCardsParams,
  IssueSingleCardResponseResponse,
  IssueSingleCardParams,
  LoadCardsResponseResponse,
  LoadCardsParams,
  OrderAndShipCardsResponseResponse,
  OrderAndShipCardsParams,
  GetBusinessPrefundingBalanceResponseResponse,
  GetBusinessPrefundingBalanceParams,
  AddTagToCardsResponseResponse,
  AddTagToCardsParams,
  SendVirtualCardRemindersResponseResponse,
  IssueVirtualCardsParamsBody,
  IssueVirtualCardsResponseResponse,
  MatchCardInfoResponseResponse,
  MatchCardInfoParams,
  RemoveMerchantRuleFromCardsResponseResponse,
  RemoveMerchantRuleFromCardsParams,
  AddMerchantRuleToCardsResponseResponse,
  AddMerchantRuleToCardsParams,
  GetCardActivitiesResponseBody,
  GetCardBalanceResponseResponse,
  GetPaymentCardClientTokenResponseBody,
  CloseCardResponseBody,
  CloseCardParams,
  GetCardDetailsResponseResponse,
  GetCardDetailsParams,
  FreezeCardResponseResponse,
  GetCardLoadHistoryResponseResponse,
  GetCardLoadHistoryParams,
  LockCardResponseResponse,
  SetCardPinResponseResponse,
  SetCardPinParamsBody,
  ReassociateCardResponseBody,
  ReassociateCardParams,
  ReissueAndShipCardResponseResponse,
  ReissueAndShipCardParams,
  ReissueVirtualCardResponseResponse,
  ResendVirtualCardResponseResponse,
  GetSpendRulesOnCardResponseResponse,
  DeleteTagFromCardResponseResponse,
  GetCardTransactionsResponseResponse,
  GetCardTransactionsParams,
  UnfreezeCardResponseResponse,
  UnlockCardResponseResponse,
  WithdrawFundsFromCardResponseBody,
  WithdrawFundsFromCardParams,
  SubmitBeneficialOwnerInfoResponseResponse,
  SubmitBeneficialOwnerInfoParams,
  GetBeneficialOwnersResponseResponse,
  GetDocumentUploadLinkResponseResponse,
  GetDocumentUploadLinkParams,
  GetDocumentUploadTypesResponseBody,
  GetDocumentUploadTypesParams,
  GetPrimaryAuthorizedPersonResponseResponse,
  AddPrimaryAuthorizedPersonResponseResponse,
  AddPrimaryAuthorizedPersonParams,
  SubmitPrimaryAuthorizedPersonResponseResponse,
  SubmitPrimaryAuthorizedPersonParams,
  SubmitApplicationParams,
  EndDocumentUploadSessionParams,
  CallPingResponseResponse,
  GetUploadSessionSubmissionStatusResponseBody,
  GetUploadSessionSubmissionStatusParams,
  CreateUserResponseResponse,
  CreateUserParams,
  CreateBusinessResponseResponse,
  CreateBusinessParams,
  UpdateNoteResponseResponse,
  UpdateNoteParams,
  SendResetUserPasswordEmailParams,
  GetUserLoginTypeResponseResponse,
  GetUserResponseResponse,
  UpdateUserResponseResponse,
  UpdateUserParams,
  CreateNoteResponseResponse,
  CreateNoteParams,
  GetNotesResponseResponse,
  GetNotesParams
} from './models'
import { client } from './mutator/client'
import type { ErrorType } from './mutator/client'


/**
 * Gets all the autoload profiles within a given business. Optionally query by status.
 * @summary Get Business's Autoload Profiles
 */
export const getAutoloadProfiles = (
    businessId: string,
    params?: GetAutoloadProfilesParams,
 signal?: AbortSignal
) => {
      return client<GetAutoloadProfilesResponseResponse>(
      {url: `/autoloads/business/${businessId}`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetAutoloadProfilesQueryKey = (businessId: string,
    params?: GetAutoloadProfilesParams,) => [`/autoloads/business/${businessId}`, ...(params ? [params]: [])];

    
export type GetAutoloadProfilesQueryResult = NonNullable<Awaited<ReturnType<typeof getAutoloadProfiles>>>
export type GetAutoloadProfilesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetAutoloadProfiles = <TData = Awaited<ReturnType<typeof getAutoloadProfiles>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetAutoloadProfilesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAutoloadProfiles>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAutoloadProfilesQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutoloadProfiles>>> = ({ signal }) => getAutoloadProfiles(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAutoloadProfiles>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Creates an autoload profile. It requires a start date, name, frequency, amount and can optionally take an end date, in the format YYYY-MM-DD and a special case. Special case 'last_of_month' will load on the last day of the month.
 * @summary Create Autoload Profile
 */
export const createAutoloadProfile = (
    businessId: string,
    createAutoloadProfileParams: CreateAutoloadProfileParams,
 ) => {
      return client<CreateAutoloadProfileResponseResponse>(
      {url: `/autoloads/business/${businessId}/autoload`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createAutoloadProfileParams
    },
      );
    }
  


    export type CreateAutoloadProfileMutationResult = NonNullable<Awaited<ReturnType<typeof createAutoloadProfile>>>
    export type CreateAutoloadProfileMutationBody = CreateAutoloadProfileParams
    export type CreateAutoloadProfileMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateAutoloadProfile = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createAutoloadProfile>>, TError,{businessId: string;data: CreateAutoloadProfileParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createAutoloadProfile>>, {businessId: string;data: CreateAutoloadProfileParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  createAutoloadProfile(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createAutoloadProfile>>, TError, {businessId: string;data: CreateAutoloadProfileParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the autoload profile and all of the cards associated with it.
 * @summary Get Autoload Profile
 */
export const getAutoloadProfile = (
    autoloadProfileId: string,
 signal?: AbortSignal
) => {
      return client<GetAutoloadProfileResponseResponse>(
      {url: `/autoloads/${autoloadProfileId}`, method: 'get', signal
    },
      );
    }
  

export const getGetAutoloadProfileQueryKey = (autoloadProfileId: string,) => [`/autoloads/${autoloadProfileId}`];

    
export type GetAutoloadProfileQueryResult = NonNullable<Awaited<ReturnType<typeof getAutoloadProfile>>>
export type GetAutoloadProfileQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetAutoloadProfile = <TData = Awaited<ReturnType<typeof getAutoloadProfile>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 autoloadProfileId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getAutoloadProfile>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetAutoloadProfileQueryKey(autoloadProfileId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getAutoloadProfile>>> = ({ signal }) => getAutoloadProfile(autoloadProfileId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getAutoloadProfile>>, TError, TData>(queryKey, queryFn, {enabled: !!(autoloadProfileId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Modifies an autoload profile. Modifications to a date must be in the format YYYY-MM-DD. If the frequency or special case changes, a start date for the changes to take place must be provided.
 * @summary Modify Autoload Profile
 */
export const modifyAutoloadProfile = (
    autoloadProfileId: string,
    modifyAutoloadProfileParams: ModifyAutoloadProfileParams,
 ) => {
      return client<ModifyAutoloadProfileResponseResponse>(
      {url: `/autoloads/${autoloadProfileId}`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: modifyAutoloadProfileParams
    },
      );
    }
  


    export type ModifyAutoloadProfileMutationResult = NonNullable<Awaited<ReturnType<typeof modifyAutoloadProfile>>>
    export type ModifyAutoloadProfileMutationBody = ModifyAutoloadProfileParams
    export type ModifyAutoloadProfileMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useModifyAutoloadProfile = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof modifyAutoloadProfile>>, TError,{autoloadProfileId: string;data: ModifyAutoloadProfileParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof modifyAutoloadProfile>>, {autoloadProfileId: string;data: ModifyAutoloadProfileParams}> = (props) => {
          const {autoloadProfileId,data} = props ?? {};

          return  modifyAutoloadProfile(autoloadProfileId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof modifyAutoloadProfile>>, TError, {autoloadProfileId: string;data: ModifyAutoloadProfileParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Adds card(s) to an autoload.
 * @summary Add Cards to Autoload
 */
export const addCardsToAutoload = (
    autoloadProfileId: string,
    addCardsToAutoloadParams: AddCardsToAutoloadParams,
 ) => {
      return client<AddCardsToAutoloadResponseResponse>(
      {url: `/autoloads/${autoloadProfileId}/members`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addCardsToAutoloadParams
    },
      );
    }
  


    export type AddCardsToAutoloadMutationResult = NonNullable<Awaited<ReturnType<typeof addCardsToAutoload>>>
    export type AddCardsToAutoloadMutationBody = AddCardsToAutoloadParams
    export type AddCardsToAutoloadMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useAddCardsToAutoload = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCardsToAutoload>>, TError,{autoloadProfileId: string;data: AddCardsToAutoloadParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCardsToAutoload>>, {autoloadProfileId: string;data: AddCardsToAutoloadParams}> = (props) => {
          const {autoloadProfileId,data} = props ?? {};

          return  addCardsToAutoload(autoloadProfileId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addCardsToAutoload>>, TError, {autoloadProfileId: string;data: AddCardsToAutoloadParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes card(s) from an autoload.
 * @summary Delete Cards from Autoload
 */
export const deleteCardsFromAutoload = (
    autoloadProfileId: string,
    deleteCardsFromAutoloadParams: DeleteCardsFromAutoloadParams,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/autoloads/${autoloadProfileId}/members`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteCardsFromAutoloadParams
    },
      );
    }
  


    export type DeleteCardsFromAutoloadMutationResult = NonNullable<Awaited<ReturnType<typeof deleteCardsFromAutoload>>>
    export type DeleteCardsFromAutoloadMutationBody = DeleteCardsFromAutoloadParams
    export type DeleteCardsFromAutoloadMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useDeleteCardsFromAutoload = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteCardsFromAutoload>>, TError,{autoloadProfileId: string;data: DeleteCardsFromAutoloadParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteCardsFromAutoload>>, {autoloadProfileId: string;data: DeleteCardsFromAutoloadParams}> = (props) => {
          const {autoloadProfileId,data} = props ?? {};

          return  deleteCardsFromAutoload(autoloadProfileId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteCardsFromAutoload>>, TError, {autoloadProfileId: string;data: DeleteCardsFromAutoloadParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes a bank account from a recipient
 * @summary Delete Bank Account
 */
export const deleteBankAccount = (
    bankAccountId: string,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/bankTransfers/bankAccount/${bankAccountId}/`, method: 'delete'
    },
      );
    }
  


    export type DeleteBankAccountMutationResult = NonNullable<Awaited<ReturnType<typeof deleteBankAccount>>>
    
    export type DeleteBankAccountMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useDeleteBankAccount = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteBankAccount>>, TError,{bankAccountId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteBankAccount>>, {bankAccountId: string}> = (props) => {
          const {bankAccountId} = props ?? {};

          return  deleteBankAccount(bankAccountId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteBankAccount>>, TError, {bankAccountId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a bank transfer recipient
 * @summary Create Bank Transfer Recipient
 */
export const createBankTransferRecipient = (
    createBankTransferRecipientParams: CreateBankTransferRecipientParams,
 ) => {
      return client<CreateBankTransferRecipientResponseResponse>(
      {url: `/bankTransfers/recipient`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createBankTransferRecipientParams
    },
      );
    }
  


    export type CreateBankTransferRecipientMutationResult = NonNullable<Awaited<ReturnType<typeof createBankTransferRecipient>>>
    export type CreateBankTransferRecipientMutationBody = CreateBankTransferRecipientParams
    export type CreateBankTransferRecipientMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateBankTransferRecipient = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBankTransferRecipient>>, TError,{data: CreateBankTransferRecipientParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBankTransferRecipient>>, {data: CreateBankTransferRecipientParams}> = (props) => {
          const {data} = props ?? {};

          return  createBankTransferRecipient(data,)
        }

      return useMutation<Awaited<ReturnType<typeof createBankTransferRecipient>>, TError, {data: CreateBankTransferRecipientParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates a recipient's first and/or last name.
 * @summary Update Recipient
 */
export const updateRecipient = (
    recipientId: string,
    updateRecipientParams: UpdateRecipientParams,
 ) => {
      return client<UpdateRecipientResponseResponse>(
      {url: `/bankTransfers/recipient/${recipientId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateRecipientParams
    },
      );
    }
  


    export type UpdateRecipientMutationResult = NonNullable<Awaited<ReturnType<typeof updateRecipient>>>
    export type UpdateRecipientMutationBody = UpdateRecipientParams
    export type UpdateRecipientMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUpdateRecipient = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateRecipient>>, TError,{recipientId: string;data: UpdateRecipientParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateRecipient>>, {recipientId: string;data: UpdateRecipientParams}> = (props) => {
          const {recipientId,data} = props ?? {};

          return  updateRecipient(recipientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateRecipient>>, TError, {recipientId: string;data: UpdateRecipientParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a recipient and adds a bank account in a single step
 * @summary Create Recipient with Bank Account
 */
export const createRecipientWithBankAccount = (
    createRecipientWithBankAccountParams: CreateRecipientWithBankAccountParams,
 ) => {
      return client<CreateRecipientWithBankAccountResponseResponse>(
      {url: `/bankTransfers/recipientWithBankAccount`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createRecipientWithBankAccountParams
    },
      );
    }
  


    export type CreateRecipientWithBankAccountMutationResult = NonNullable<Awaited<ReturnType<typeof createRecipientWithBankAccount>>>
    export type CreateRecipientWithBankAccountMutationBody = CreateRecipientWithBankAccountParams
    export type CreateRecipientWithBankAccountMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateRecipientWithBankAccount = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createRecipientWithBankAccount>>, TError,{data: CreateRecipientWithBankAccountParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createRecipientWithBankAccount>>, {data: CreateRecipientWithBankAccountParams}> = (props) => {
          const {data} = props ?? {};

          return  createRecipientWithBankAccount(data,)
        }

      return useMutation<Awaited<ReturnType<typeof createRecipientWithBankAccount>>, TError, {data: CreateRecipientWithBankAccountParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves all recipients associated with business
 * @summary Get Business's Bank Transfer Recipients
 */
export const getBankTransferRecipients = (
    params?: GetBankTransferRecipientsParams,
 signal?: AbortSignal
) => {
      return client<GetBankTransferRecipientsResponseResponse>(
      {url: `/bankTransfers/recipients`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBankTransferRecipientsQueryKey = (params?: GetBankTransferRecipientsParams,) => [`/bankTransfers/recipients`, ...(params ? [params]: [])];

    
export type GetBankTransferRecipientsQueryResult = NonNullable<Awaited<ReturnType<typeof getBankTransferRecipients>>>
export type GetBankTransferRecipientsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBankTransferRecipients = <TData = Awaited<ReturnType<typeof getBankTransferRecipients>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 params?: GetBankTransferRecipientsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBankTransferRecipients>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankTransferRecipientsQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankTransferRecipients>>> = ({ signal }) => getBankTransferRecipients(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBankTransferRecipients>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieves a bank transfer recipient by id
 * @summary Get Bank Transfer Recipient
 */
export const getBankTransferRecipient = (
    recipientId: string,
 signal?: AbortSignal
) => {
      return client<GetBankTransferRecipientResponseResponse>(
      {url: `/bankTransfers/recipients/${recipientId}/`, method: 'get', signal
    },
      );
    }
  

export const getGetBankTransferRecipientQueryKey = (recipientId: string,) => [`/bankTransfers/recipients/${recipientId}/`];

    
export type GetBankTransferRecipientQueryResult = NonNullable<Awaited<ReturnType<typeof getBankTransferRecipient>>>
export type GetBankTransferRecipientQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBankTransferRecipient = <TData = Awaited<ReturnType<typeof getBankTransferRecipient>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 recipientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBankTransferRecipient>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankTransferRecipientQueryKey(recipientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankTransferRecipient>>> = ({ signal }) => getBankTransferRecipient(recipientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBankTransferRecipient>>, TError, TData>(queryKey, queryFn, {enabled: !!(recipientId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Fetches recipient activities
 * @summary Get Bank Transfer Recipient Activities
 */
export const getRecipientActivities = (
    recipientId: string,
 signal?: AbortSignal
) => {
      return client<GetRecipientActivitiesResponseBody>(
      {url: `/bankTransfers/recipients/${recipientId}/activities`, method: 'get', signal
    },
      );
    }
  

export const getGetRecipientActivitiesQueryKey = (recipientId: string,) => [`/bankTransfers/recipients/${recipientId}/activities`];

    
export type GetRecipientActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getRecipientActivities>>>
export type GetRecipientActivitiesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetRecipientActivities = <TData = Awaited<ReturnType<typeof getRecipientActivities>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 recipientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getRecipientActivities>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetRecipientActivitiesQueryKey(recipientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getRecipientActivities>>> = ({ signal }) => getRecipientActivities(recipientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getRecipientActivities>>, TError, TData>(queryKey, queryFn, {enabled: !!(recipientId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Adds a bank account to a recipient
 * @summary Add Bank Account to Recipient
 */
export const addBankAccountToRecipient = (
    recipientId: string,
    addBankAccountToRecipientParams: AddBankAccountToRecipientParams,
 ) => {
      return client<AddBankAccountToRecipientResponseResponse>(
      {url: `/bankTransfers/recipients/${recipientId}/bankAccount`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addBankAccountToRecipientParams
    },
      );
    }
  


    export type AddBankAccountToRecipientMutationResult = NonNullable<Awaited<ReturnType<typeof addBankAccountToRecipient>>>
    export type AddBankAccountToRecipientMutationBody = AddBankAccountToRecipientParams
    export type AddBankAccountToRecipientMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useAddBankAccountToRecipient = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addBankAccountToRecipient>>, TError,{recipientId: string;data: AddBankAccountToRecipientParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addBankAccountToRecipient>>, {recipientId: string;data: AddBankAccountToRecipientParams}> = (props) => {
          const {recipientId,data} = props ?? {};

          return  addBankAccountToRecipient(recipientId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addBankAccountToRecipient>>, TError, {recipientId: string;data: AddBankAccountToRecipientParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves all bank transfers sent to a given recipient. Returns transfers of all statuses (failed, processing, completed, etc).
 * @summary Get Bank Transfers for Recipient
 */
export const getBankTransfersForRecipient = (
    recipientId: string,
 signal?: AbortSignal
) => {
      return client<GetBankTransfersForRecipientResponseResponse>(
      {url: `/bankTransfers/recipients/${recipientId}/transfers`, method: 'get', signal
    },
      );
    }
  

export const getGetBankTransfersForRecipientQueryKey = (recipientId: string,) => [`/bankTransfers/recipients/${recipientId}/transfers`];

    
export type GetBankTransfersForRecipientQueryResult = NonNullable<Awaited<ReturnType<typeof getBankTransfersForRecipient>>>
export type GetBankTransfersForRecipientQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBankTransfersForRecipient = <TData = Awaited<ReturnType<typeof getBankTransfersForRecipient>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 recipientId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBankTransfersForRecipient>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankTransfersForRecipientQueryKey(recipientId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankTransfersForRecipient>>> = ({ signal }) => getBankTransfersForRecipient(recipientId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBankTransfersForRecipient>>, TError, TData>(queryKey, queryFn, {enabled: !!(recipientId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieves all transfers associated with the business. Optionally query by transfer status, speed, start date and end date.
 * @summary Get Bank Transfers for Business
 */
export const getBankTransfers = (
    params?: GetBankTransfersParams,
 signal?: AbortSignal
) => {
      return client<GetBankTransfersResponseResponse>(
      {url: `/bankTransfers/transfers`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBankTransfersQueryKey = (params?: GetBankTransfersParams,) => [`/bankTransfers/transfers`, ...(params ? [params]: [])];

    
export type GetBankTransfersQueryResult = NonNullable<Awaited<ReturnType<typeof getBankTransfers>>>
export type GetBankTransfersQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBankTransfers = <TData = Awaited<ReturnType<typeof getBankTransfers>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 params?: GetBankTransfersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBankTransfers>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankTransfersQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankTransfers>>> = ({ signal }) => getBankTransfers(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBankTransfers>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Initiates bank transfers to the given recipients.
 * @summary Initiate Bank Transfers
 */
export const initiateBankAccountTransfers = (
    initiateBankAccountTransfersParams: InitiateBankAccountTransfersParams,
 ) => {
      return client<InitiateBankAccountTransfersResponseResponse>(
      {url: `/bankTransfers/transfers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: initiateBankAccountTransfersParams
    },
      );
    }
  


    export type InitiateBankAccountTransfersMutationResult = NonNullable<Awaited<ReturnType<typeof initiateBankAccountTransfers>>>
    export type InitiateBankAccountTransfersMutationBody = InitiateBankAccountTransfersParams
    export type InitiateBankAccountTransfersMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useInitiateBankAccountTransfers = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof initiateBankAccountTransfers>>, TError,{data: InitiateBankAccountTransfersParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof initiateBankAccountTransfers>>, {data: InitiateBankAccountTransfersParams}> = (props) => {
          const {data} = props ?? {};

          return  initiateBankAccountTransfers(data,)
        }

      return useMutation<Awaited<ReturnType<typeof initiateBankAccountTransfers>>, TError, {data: InitiateBankAccountTransfersParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves a specific bank transfer record by id
 * @summary Get Bank Transfer
 */
export const getBankTransfer = (
    transferId: string,
 signal?: AbortSignal
) => {
      return client<GetBankTransferResponseResponse>(
      {url: `/bankTransfers/transfers/${transferId}/`, method: 'get', signal
    },
      );
    }
  

export const getGetBankTransferQueryKey = (transferId: string,) => [`/bankTransfers/transfers/${transferId}/`];

    
export type GetBankTransferQueryResult = NonNullable<Awaited<ReturnType<typeof getBankTransfer>>>
export type GetBankTransferQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBankTransfer = <TData = Awaited<ReturnType<typeof getBankTransfer>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 transferId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBankTransfer>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBankTransferQueryKey(transferId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBankTransfer>>> = ({ signal }) => getBankTransfer(transferId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBankTransfer>>, TError, TData>(queryKey, queryFn, {enabled: !!(transferId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns a merchant rule & all of its restrictions
 * @summary Get Merchant Rule
 */
export const getMerchantRule = (
    merchantRuleId: string,
 signal?: AbortSignal
) => {
      return client<GetMerchantRuleResponseResponse>(
      {url: `/businesses/merchantRule/${merchantRuleId}/`, method: 'get', signal
    },
      );
    }
  

export const getGetMerchantRuleQueryKey = (merchantRuleId: string,) => [`/businesses/merchantRule/${merchantRuleId}/`];

    
export type GetMerchantRuleQueryResult = NonNullable<Awaited<ReturnType<typeof getMerchantRule>>>
export type GetMerchantRuleQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetMerchantRule = <TData = Awaited<ReturnType<typeof getMerchantRule>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 merchantRuleId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getMerchantRule>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetMerchantRuleQueryKey(merchantRuleId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getMerchantRule>>> = ({ signal }) => getMerchantRule(merchantRuleId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getMerchantRule>>, TError, TData>(queryKey, queryFn, {enabled: !!(merchantRuleId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Modifies a merchant rule
 * @summary Update Merchant Rule
 */
export const updateMerchantRule = (
    merchantRuleId: string,
    updateMerchantRuleParams: UpdateMerchantRuleParams,
 ) => {
      return client<UpdateMerchantRuleResponseResponse>(
      {url: `/businesses/merchantRule/${merchantRuleId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateMerchantRuleParams
    },
      );
    }
  


    export type UpdateMerchantRuleMutationResult = NonNullable<Awaited<ReturnType<typeof updateMerchantRule>>>
    export type UpdateMerchantRuleMutationBody = UpdateMerchantRuleParams
    export type UpdateMerchantRuleMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUpdateMerchantRule = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMerchantRule>>, TError,{merchantRuleId: string;data: UpdateMerchantRuleParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMerchantRule>>, {merchantRuleId: string;data: UpdateMerchantRuleParams}> = (props) => {
          const {merchantRuleId,data} = props ?? {};

          return  updateMerchantRule(merchantRuleId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateMerchantRule>>, TError, {merchantRuleId: string;data: UpdateMerchantRuleParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Adds metadata to a resource
 * @summary Add Metadata to Resource
 */
export const addMetadata = (
    addMetadataParams: AddMetadataParams,
 ) => {
      return client<AddMetadataResponseResponse>(
      {url: `/businesses/metadata`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addMetadataParams
    },
      );
    }
  


    export type AddMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof addMetadata>>>
    export type AddMetadataMutationBody = AddMetadataParams
    export type AddMetadataMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useAddMetadata = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addMetadata>>, TError,{data: AddMetadataParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addMetadata>>, {data: AddMetadataParams}> = (props) => {
          const {data} = props ?? {};

          return  addMetadata(data,)
        }

      return useMutation<Awaited<ReturnType<typeof addMetadata>>, TError, {data: AddMetadataParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes metadata from a resource by key
 * @summary Delete Metadata
 */
export const deleteMetadata = (
    deleteMetadataParams: DeleteMetadataParams,
 ) => {
      return client<DeleteMetadataResponseResponse>(
      {url: `/businesses/metadata`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: deleteMetadataParams
    },
      );
    }
  


    export type DeleteMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof deleteMetadata>>>
    export type DeleteMetadataMutationBody = DeleteMetadataParams
    export type DeleteMetadataMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useDeleteMetadata = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteMetadata>>, TError,{data: DeleteMetadataParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteMetadata>>, {data: DeleteMetadataParams}> = (props) => {
          const {data} = props ?? {};

          return  deleteMetadata(data,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteMetadata>>, TError, {data: DeleteMetadataParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates a resource's metadata. Pass the resource id and the key/value pair you want to update on that resource.
 * @summary Update Metadata
 */
export const updateMetadata = (
    updateMetadataParams: UpdateMetadataParams,
 ) => {
      return client<UpdateMetadataResponseResponse>(
      {url: `/businesses/metadata`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateMetadataParams
    },
      );
    }
  


    export type UpdateMetadataMutationResult = NonNullable<Awaited<ReturnType<typeof updateMetadata>>>
    export type UpdateMetadataMutationBody = UpdateMetadataParams
    export type UpdateMetadataMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUpdateMetadata = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateMetadata>>, TError,{data: UpdateMetadataParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateMetadata>>, {data: UpdateMetadataParams}> = (props) => {
          const {data} = props ?? {};

          return  updateMetadata(data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateMetadata>>, TError, {data: UpdateMetadataParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets the url for your business's webhooks portal
 * @summary Get Webhooks Portal Access Link
 */
export const getWebhooksPortalAccessLink = (
    
 signal?: AbortSignal
) => {
      return client<GetWebhooksPortalAccessLinkResponseResponse>(
      {url: `/businesses/webhooks/accessLink`, method: 'get', signal
    },
      );
    }
  

export const getGetWebhooksPortalAccessLinkQueryKey = () => [`/businesses/webhooks/accessLink`];

    
export type GetWebhooksPortalAccessLinkQueryResult = NonNullable<Awaited<ReturnType<typeof getWebhooksPortalAccessLink>>>
export type GetWebhooksPortalAccessLinkQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetWebhooksPortalAccessLink = <TData = Awaited<ReturnType<typeof getWebhooksPortalAccessLink>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getWebhooksPortalAccessLink>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetWebhooksPortalAccessLinkQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getWebhooksPortalAccessLink>>> = ({ signal }) => getWebhooksPortalAccessLink(signal);

  const query = useQuery<Awaited<ReturnType<typeof getWebhooksPortalAccessLink>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the business's address
 * @summary Get Business's Address
 */
export const getBusinessAddress = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetBusinessAddressResponseResponse>(
      {url: `/businesses/${businessId}/address`, method: 'get', signal
    },
      );
    }
  

export const getGetBusinessAddressQueryKey = (businessId: string,) => [`/businesses/${businessId}/address`];

    
export type GetBusinessAddressQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessAddress>>>
export type GetBusinessAddressQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessAddress = <TData = Awaited<ReturnType<typeof getBusinessAddress>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessAddress>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessAddressQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessAddress>>> = ({ signal }) => getBusinessAddress(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessAddress>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Generates an API key for the business
 * @summary Generate API Key
 */
export const generateApiKey = (
    businessId: string,
 ) => {
      return client<GenerateApiKeyResponseResponse>(
      {url: `/businesses/${businessId}/apiKey`, method: 'post'
    },
      );
    }
  


    export type GenerateApiKeyMutationResult = NonNullable<Awaited<ReturnType<typeof generateApiKey>>>
    
    export type GenerateApiKeyMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useGenerateApiKey = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof generateApiKey>>, TError,{businessId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof generateApiKey>>, {businessId: string}> = (props) => {
          const {businessId} = props ?? {};

          return  generateApiKey(businessId,)
        }

      return useMutation<Awaited<ReturnType<typeof generateApiKey>>, TError, {businessId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all card ids within the business, with optional filters. Used for selecting all in the card dashboard (which has paginated results and no access to the full array of records).
 * @summary Get Business Card Ids
 */
export const getBusinessCardIds = (
    businessId: string,
    params?: GetBusinessCardIdsParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessCardIdsResponseResponse>(
      {url: `/businesses/${businessId}/cardIds`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessCardIdsQueryKey = (businessId: string,
    params?: GetBusinessCardIdsParams,) => [`/businesses/${businessId}/cardIds`, ...(params ? [params]: [])];

    
export type GetBusinessCardIdsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessCardIds>>>
export type GetBusinessCardIdsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessCardIds = <TData = Awaited<ReturnType<typeof getBusinessCardIds>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessCardIdsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessCardIds>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessCardIdsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessCardIds>>> = ({ signal }) => getBusinessCardIds(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessCardIds>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the count of cards ordered in the given time range.
 * @summary Get Ordered Cards Count
 */
export const getOrderCardHistory = (
    businessId: string,
    params?: GetOrderCardHistoryParams,
 signal?: AbortSignal
) => {
      return client<GetOrderCardHistoryResponseResponse>(
      {url: `/businesses/${businessId}/cardOrderHistory`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetOrderCardHistoryQueryKey = (businessId: string,
    params?: GetOrderCardHistoryParams,) => [`/businesses/${businessId}/cardOrderHistory`, ...(params ? [params]: [])];

    
export type GetOrderCardHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getOrderCardHistory>>>
export type GetOrderCardHistoryQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetOrderCardHistory = <TData = Awaited<ReturnType<typeof getOrderCardHistory>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetOrderCardHistoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getOrderCardHistory>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetOrderCardHistoryQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getOrderCardHistory>>> = ({ signal }) => getOrderCardHistory(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getOrderCardHistory>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns all card orders for the given business
 * @summary Get Business's Card Orders
 */
export const getBusinessCardOrders = (
    businessId: string,
    params?: GetBusinessCardOrdersParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessCardOrdersResponseResponse>(
      {url: `/businesses/${businessId}/cardOrders`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessCardOrdersQueryKey = (businessId: string,
    params?: GetBusinessCardOrdersParams,) => [`/businesses/${businessId}/cardOrders`, ...(params ? [params]: [])];

    
export type GetBusinessCardOrdersQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessCardOrders>>>
export type GetBusinessCardOrdersQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessCardOrders = <TData = Awaited<ReturnType<typeof getBusinessCardOrders>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessCardOrdersParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessCardOrders>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessCardOrdersQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessCardOrders>>> = ({ signal }) => getBusinessCardOrders(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessCardOrders>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns an individual card orders details
 * @summary Get Individual Card Order
 */
export const getIndividualCardOrder = (
    businessId: string,
    cardOrderId: string,
 signal?: AbortSignal
) => {
      return client<GetIndividualCardOrderResponseResponse>(
      {url: `/businesses/${businessId}/cardOrders/${cardOrderId}`, method: 'get', signal
    },
      );
    }
  

export const getGetIndividualCardOrderQueryKey = (businessId: string,
    cardOrderId: string,) => [`/businesses/${businessId}/cardOrders/${cardOrderId}`];

    
export type GetIndividualCardOrderQueryResult = NonNullable<Awaited<ReturnType<typeof getIndividualCardOrder>>>
export type GetIndividualCardOrderQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetIndividualCardOrder = <TData = Awaited<ReturnType<typeof getIndividualCardOrder>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    cardOrderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getIndividualCardOrder>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetIndividualCardOrderQueryKey(businessId,cardOrderId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getIndividualCardOrder>>> = ({ signal }) => getIndividualCardOrder(businessId,cardOrderId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getIndividualCardOrder>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId && cardOrderId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Transfers funding between funding accounts. Note that programs must be of the same product type in order to transfer funds (cards, bank transfers, etc).
 * @summary Transfer Funds Between Funding Accounts
 */
export const transferFundingBetweenFundingAccounts = (
    businessId: string,
    transferFundsBetweenFundingAccountsParams: TransferFundsBetweenFundingAccountsParams,
 ) => {
      return client<TransferFundsBetweenFundingAccountsResponseResponse>(
      {url: `/businesses/${businessId}/fundingAccount/transfer`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: transferFundsBetweenFundingAccountsParams
    },
      );
    }
  


    export type TransferFundingBetweenFundingAccountsMutationResult = NonNullable<Awaited<ReturnType<typeof transferFundingBetweenFundingAccounts>>>
    export type TransferFundingBetweenFundingAccountsMutationBody = TransferFundsBetweenFundingAccountsParams
    export type TransferFundingBetweenFundingAccountsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useTransferFundingBetweenFundingAccounts = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof transferFundingBetweenFundingAccounts>>, TError,{businessId: string;data: TransferFundsBetweenFundingAccountsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof transferFundingBetweenFundingAccounts>>, {businessId: string;data: TransferFundsBetweenFundingAccountsParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  transferFundingBetweenFundingAccounts(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof transferFundingBetweenFundingAccounts>>, TError, {businessId: string;data: TransferFundsBetweenFundingAccountsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all funding account deposits for a given business
 * @summary Get Funding Account Deposits
 */
export const getFundingAccountDeposits = (
    businessId: string,
    params?: GetFundingAccountDepositsParams,
 signal?: AbortSignal
) => {
      return client<GetFundingAccountDepositsResponseResponse>(
      {url: `/businesses/${businessId}/fundingAccountDeposits`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetFundingAccountDepositsQueryKey = (businessId: string,
    params?: GetFundingAccountDepositsParams,) => [`/businesses/${businessId}/fundingAccountDeposits`, ...(params ? [params]: [])];

    
export type GetFundingAccountDepositsQueryResult = NonNullable<Awaited<ReturnType<typeof getFundingAccountDeposits>>>
export type GetFundingAccountDepositsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetFundingAccountDeposits = <TData = Awaited<ReturnType<typeof getFundingAccountDeposits>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetFundingAccountDepositsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFundingAccountDeposits>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetFundingAccountDepositsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getFundingAccountDeposits>>> = ({ signal }) => getFundingAccountDeposits(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getFundingAccountDeposits>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the funding accounts associated with the business. For programs and normal scope, this will return only 1 funding account currently. For superbusinesses, this will return all the superbusiness funding accounts and its programs' funding accounts, along with the balance and scope of each funding account.
 * @summary Get a Business's Funding Accounts
 */
export const getBusinessFundingAccounts = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetBusinessFundingAccountsResponseResponse>(
      {url: `/businesses/${businessId}/fundingAccounts`, method: 'get', signal
    },
      );
    }
  

export const getGetBusinessFundingAccountsQueryKey = (businessId: string,) => [`/businesses/${businessId}/fundingAccounts`];

    
export type GetBusinessFundingAccountsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessFundingAccounts>>>
export type GetBusinessFundingAccountsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessFundingAccounts = <TData = Awaited<ReturnType<typeof getBusinessFundingAccounts>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessFundingAccounts>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessFundingAccountsQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessFundingAccounts>>> = ({ signal }) => getBusinessFundingAccounts(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessFundingAccounts>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Invites new users to create GiveCard Platform accounts.
 * @summary Invite Users
 */
export const inviteUsers = (
    businessId: string,
    inviteUsersParams: InviteUsersParams,
 ) => {
      return client<InviteUsersResponseResponse>(
      {url: `/businesses/${businessId}/inviteUsers`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: inviteUsersParams
    },
      );
    }
  


    export type InviteUsersMutationResult = NonNullable<Awaited<ReturnType<typeof inviteUsers>>>
    export type InviteUsersMutationBody = InviteUsersParams
    export type InviteUsersMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useInviteUsers = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inviteUsers>>, TError,{businessId: string;data: InviteUsersParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inviteUsers>>, {businessId: string;data: InviteUsersParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  inviteUsers(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof inviteUsers>>, TError, {businessId: string;data: InviteUsersParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all card loads, withdrawals and transfers. It also accepts tag value queries and to and from queries, in the RFC3339 format YYYY-MM-DDThh:mm:ssZ.
 * @summary Get Business's Load History
 */
export const getBusinessLoadHistory = (
    businessId: string,
    params?: GetBusinessLoadHistoryParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessLoadHistoryResponseResponse>(
      {url: `/businesses/${businessId}/loadHistory`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessLoadHistoryQueryKey = (businessId: string,
    params?: GetBusinessLoadHistoryParams,) => [`/businesses/${businessId}/loadHistory`, ...(params ? [params]: [])];

    
export type GetBusinessLoadHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessLoadHistory>>>
export type GetBusinessLoadHistoryQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessLoadHistory = <TData = Awaited<ReturnType<typeof getBusinessLoadHistory>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessLoadHistoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessLoadHistory>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessLoadHistoryQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessLoadHistory>>> = ({ signal }) => getBusinessLoadHistory(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessLoadHistory>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Creates a rule that allows or blocks specified merchant categories or keywords
 * @summary Create Merchant Rule
 */
export const createMerchantRule = (
    businessId: string,
    createMerchantRuleParams: CreateMerchantRuleParams,
 ) => {
      return client<CreateMerchantRuleResponseResponse>(
      {url: `/businesses/${businessId}/merchantRule`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createMerchantRuleParams
    },
      );
    }
  


    export type CreateMerchantRuleMutationResult = NonNullable<Awaited<ReturnType<typeof createMerchantRule>>>
    export type CreateMerchantRuleMutationBody = CreateMerchantRuleParams
    export type CreateMerchantRuleMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateMerchantRule = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createMerchantRule>>, TError,{businessId: string;data: CreateMerchantRuleParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createMerchantRule>>, {businessId: string;data: CreateMerchantRuleParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  createMerchantRule(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createMerchantRule>>, TError, {businessId: string;data: CreateMerchantRuleParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a business's merchant rules with restrictions, scope, program ID and program name if applicable.
 * @summary Get Business's Merchant Rules
 */
export const getBusinessMerchantRules = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetBusinessMerchantRulesResponseResponse>(
      {url: `/businesses/${businessId}/merchantRules`, method: 'get', signal
    },
      );
    }
  

export const getGetBusinessMerchantRulesQueryKey = (businessId: string,) => [`/businesses/${businessId}/merchantRules`];

    
export type GetBusinessMerchantRulesQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessMerchantRules>>>
export type GetBusinessMerchantRulesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessMerchantRules = <TData = Awaited<ReturnType<typeof getBusinessMerchantRules>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessMerchantRules>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessMerchantRulesQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessMerchantRules>>> = ({ signal }) => getBusinessMerchantRules(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessMerchantRules>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the ACH details for a business's prefunding account. Only returns ACH details for card programs, for bank transfer program ACH details please reach out to your GiveCard CS team.
 * @summary Get Business's Prefunding ACH Details
 */
export const getBusinessPrefundingAchDetails = (
    businessId: string,
    params?: GetBusinessPrefundingAchDetailsParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessPrefundingAchDetailsResponseResponse>(
      {url: `/businesses/${businessId}/prefundingAchDetails`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessPrefundingAchDetailsQueryKey = (businessId: string,
    params?: GetBusinessPrefundingAchDetailsParams,) => [`/businesses/${businessId}/prefundingAchDetails`, ...(params ? [params]: [])];

    
export type GetBusinessPrefundingAchDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessPrefundingAchDetails>>>
export type GetBusinessPrefundingAchDetailsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessPrefundingAchDetails = <TData = Awaited<ReturnType<typeof getBusinessPrefundingAchDetails>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessPrefundingAchDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessPrefundingAchDetails>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessPrefundingAchDetailsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessPrefundingAchDetails>>> = ({ signal }) => getBusinessPrefundingAchDetails(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessPrefundingAchDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Creates a new program for a superbusiness.
 * @summary Create Program
 */
export const createBusinessProgram = (
    businessId: string,
    createBusinessProgramParamsBody: CreateBusinessProgramParamsBody,
 ) => {
      return client<CreateBusinessProgramResponseResponse>(
      {url: `/businesses/${businessId}/program`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createBusinessProgramParamsBody
    },
      );
    }
  


    export type CreateBusinessProgramMutationResult = NonNullable<Awaited<ReturnType<typeof createBusinessProgram>>>
    export type CreateBusinessProgramMutationBody = CreateBusinessProgramParamsBody
    export type CreateBusinessProgramMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateBusinessProgram = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBusinessProgram>>, TError,{businessId: string;data: CreateBusinessProgramParamsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBusinessProgram>>, {businessId: string;data: CreateBusinessProgramParamsBody}> = (props) => {
          const {businessId,data} = props ?? {};

          return  createBusinessProgram(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createBusinessProgram>>, TError, {businessId: string;data: CreateBusinessProgramParamsBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all programs associated with a given superbusiness
 * @summary Get Programs
 */
export const getBusinessPrograms = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetBusinessProgramsResponseResponse>(
      {url: `/businesses/${businessId}/programs`, method: 'get', signal
    },
      );
    }
  

export const getGetBusinessProgramsQueryKey = (businessId: string,) => [`/businesses/${businessId}/programs`];

    
export type GetBusinessProgramsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessPrograms>>>
export type GetBusinessProgramsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessPrograms = <TData = Awaited<ReturnType<typeof getBusinessPrograms>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessPrograms>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessProgramsQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessPrograms>>> = ({ signal }) => getBusinessPrograms(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessPrograms>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns key metrics for the given bank transfers program.  Optionally filter by date/time range, transfer status, and transfer speed.
 * @summary Get Business's Key Bank Transfer Metrics
 */
export const getBusinessBankTransferMetrics = (
    businessId: string,
    params?: GetBusinessBankTransferMetricsParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessBankTransferMetricsResponseResponse>(
      {url: `/businesses/${businessId}/reporting/bankTransfers/keyMetrics`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessBankTransferMetricsQueryKey = (businessId: string,
    params?: GetBusinessBankTransferMetricsParams,) => [`/businesses/${businessId}/reporting/bankTransfers/keyMetrics`, ...(params ? [params]: [])];

    
export type GetBusinessBankTransferMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessBankTransferMetrics>>>
export type GetBusinessBankTransferMetricsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessBankTransferMetrics = <TData = Awaited<ReturnType<typeof getBusinessBankTransferMetrics>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessBankTransferMetricsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessBankTransferMetrics>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessBankTransferMetricsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessBankTransferMetrics>>> = ({ signal }) => getBusinessBankTransferMetrics(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessBankTransferMetrics>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns key metrics for the given card program.  Optionally filter by date/time range and/or tag value(s).
 * @summary Get Business's Key Card Metrics
 */
export const getBusinessCardMetrics = (
    businessId: string,
    params?: GetBusinessCardMetricsParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessCardMetricsResponseResponse>(
      {url: `/businesses/${businessId}/reporting/cards/keyMetrics`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessCardMetricsQueryKey = (businessId: string,
    params?: GetBusinessCardMetricsParams,) => [`/businesses/${businessId}/reporting/cards/keyMetrics`, ...(params ? [params]: [])];

    
export type GetBusinessCardMetricsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessCardMetrics>>>
export type GetBusinessCardMetricsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessCardMetrics = <TData = Awaited<ReturnType<typeof getBusinessCardMetrics>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessCardMetricsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessCardMetrics>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessCardMetricsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessCardMetrics>>> = ({ signal }) => getBusinessCardMetrics(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessCardMetrics>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns the mcc category percentages for the given card program. Optionally filter by date/time range and/or tag value(s).
 * @summary Get Business's Transaction Categories
 */
export const getBusinessTransactionCategories = (
    businessId: string,
    params?: GetBusinessTransactionCategoriesParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessTransactionCategoriesResponseResponse>(
      {url: `/businesses/${businessId}/reporting/cards/transactionCategories`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessTransactionCategoriesQueryKey = (businessId: string,
    params?: GetBusinessTransactionCategoriesParams,) => [`/businesses/${businessId}/reporting/cards/transactionCategories`, ...(params ? [params]: [])];

    
export type GetBusinessTransactionCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessTransactionCategories>>>
export type GetBusinessTransactionCategoriesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessTransactionCategories = <TData = Awaited<ReturnType<typeof getBusinessTransactionCategories>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessTransactionCategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessTransactionCategories>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessTransactionCategoriesQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessTransactionCategories>>> = ({ signal }) => getBusinessTransactionCategories(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessTransactionCategories>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Gets all unique tag names within a business
 * @summary Get Business's Tag Names
 */
export const getTagNamesForBusiness = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetTagNamesForBusinessResponseResponse>(
      {url: `/businesses/${businessId}/tagNames`, method: 'get', signal
    },
      );
    }
  

export const getGetTagNamesForBusinessQueryKey = (businessId: string,) => [`/businesses/${businessId}/tagNames`];

    
export type GetTagNamesForBusinessQueryResult = NonNullable<Awaited<ReturnType<typeof getTagNamesForBusiness>>>
export type GetTagNamesForBusinessQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetTagNamesForBusiness = <TData = Awaited<ReturnType<typeof getTagNamesForBusiness>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagNamesForBusiness>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagNamesForBusinessQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagNamesForBusiness>>> = ({ signal }) => getTagNamesForBusiness(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagNamesForBusiness>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Gets the unique tag values + colors for a given tag name for a business
 * @summary Get Tag Values By Tag Name
 */
export const getTagValuesByTagName = (
    businessId: string,
    params?: GetTagValuesByTagNameParams,
 signal?: AbortSignal
) => {
      return client<GetTagValuesByTagNameResponseResponse>(
      {url: `/businesses/${businessId}/tagValues`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetTagValuesByTagNameQueryKey = (businessId: string,
    params?: GetTagValuesByTagNameParams,) => [`/businesses/${businessId}/tagValues`, ...(params ? [params]: [])];

    
export type GetTagValuesByTagNameQueryResult = NonNullable<Awaited<ReturnType<typeof getTagValuesByTagName>>>
export type GetTagValuesByTagNameQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetTagValuesByTagName = <TData = Awaited<ReturnType<typeof getTagValuesByTagName>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetTagValuesByTagNameParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTagValuesByTagName>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTagValuesByTagNameQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTagValuesByTagName>>> = ({ signal }) => getTagValuesByTagName(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getTagValuesByTagName>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns all unique tags within a given business
 * @summary Get Business's Tags
 */
export const getBusinessTags = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetBusinessTagsResponseResponse>(
      {url: `/businesses/${businessId}/tags`, method: 'get', signal
    },
      );
    }
  

export const getGetBusinessTagsQueryKey = (businessId: string,) => [`/businesses/${businessId}/tags`];

    
export type GetBusinessTagsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessTags>>>
export type GetBusinessTagsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessTags = <TData = Awaited<ReturnType<typeof getBusinessTags>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessTags>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessTagsQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessTags>>> = ({ signal }) => getBusinessTags(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessTags>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns successful Cleared and AuthAndCleared transactions for a given business. It also accepts tag value queries and to and from queries, in the RFC3339 format YYYY-MM-DDThh:mm:ssZ. To show pending and/or declined transactions set those flags to true. Optionally, utilize the pageSize to paginate results.
 * @summary Get Business's Transactions
 */
export const getBusinessTransactions = (
    businessId: string,
    params?: GetBusinessTransactionsParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessTransactionsResponseResponse>(
      {url: `/businesses/${businessId}/transactions`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessTransactionsQueryKey = (businessId: string,
    params?: GetBusinessTransactionsParams,) => [`/businesses/${businessId}/transactions`, ...(params ? [params]: [])];

    
export type GetBusinessTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessTransactions>>>
export type GetBusinessTransactionsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessTransactions = <TData = Awaited<ReturnType<typeof getBusinessTransactions>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessTransactions>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessTransactionsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessTransactions>>> = ({ signal }) => getBusinessTransactions(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessTransactions>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieves all users within a given business
 * @summary Get Business's Users
 */
export const getUsersForBusiness = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetUsersForBusinessResponseResponse>(
      {url: `/businesses/${businessId}/users`, method: 'get', signal
    },
      );
    }
  

export const getGetUsersForBusinessQueryKey = (businessId: string,) => [`/businesses/${businessId}/users`];

    
export type GetUsersForBusinessQueryResult = NonNullable<Awaited<ReturnType<typeof getUsersForBusiness>>>
export type GetUsersForBusinessQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetUsersForBusiness = <TData = Awaited<ReturnType<typeof getUsersForBusiness>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUsersForBusiness>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUsersForBusinessQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUsersForBusiness>>> = ({ signal }) => getUsersForBusiness(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUsersForBusiness>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Creates a new cardholder and links them to the given card
 * @summary Create Cardholder and Link Card
 */
export const createCardholderAndLinkCard = (
    cardId: string,
    createCardholderAndLinkCardParams: CreateCardholderAndLinkCardParams,
 ) => {
      return client<CreateCardholderAndLinkCardResponseResponse>(
      {url: `/cardholders/card/${cardId}/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCardholderAndLinkCardParams
    },
      );
    }
  


    export type CreateCardholderAndLinkCardMutationResult = NonNullable<Awaited<ReturnType<typeof createCardholderAndLinkCard>>>
    export type CreateCardholderAndLinkCardMutationBody = CreateCardholderAndLinkCardParams
    export type CreateCardholderAndLinkCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateCardholderAndLinkCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCardholderAndLinkCard>>, TError,{cardId: string;data: CreateCardholderAndLinkCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCardholderAndLinkCard>>, {cardId: string;data: CreateCardholderAndLinkCardParams}> = (props) => {
          const {cardId,data} = props ?? {};

          return  createCardholderAndLinkCard(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCardholderAndLinkCard>>, TError, {cardId: string;data: CreateCardholderAndLinkCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a new cardholder after they've completed authentication
 * @summary Create Cardholder
 */
export const createCardholder = (
    createCardholderParams: CreateCardholderParams,
 ) => {
      return client<CreateCardholderResponseResponse>(
      {url: `/cardholders/cardholder`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createCardholderParams
    },
      );
    }
  


    export type CreateCardholderMutationResult = NonNullable<Awaited<ReturnType<typeof createCardholder>>>
    export type CreateCardholderMutationBody = CreateCardholderParams
    export type CreateCardholderMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateCardholder = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCardholder>>, TError,{data: CreateCardholderParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCardholder>>, {data: CreateCardholderParams}> = (props) => {
          const {data} = props ?? {};

          return  createCardholder(data,)
        }

      return useMutation<Awaited<ReturnType<typeof createCardholder>>, TError, {data: CreateCardholderParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Checks if the given phone number is associated with a cardholder
 * @summary Check for Cardholder by Phone Number
 */
export const checkCardholderByPhone = (
    phoneNumber: string,
 signal?: AbortSignal
) => {
      return client<EmptyResponseResponse>(
      {url: `/cardholders/checkByPhone/${phoneNumber}`, method: 'get', signal
    },
      );
    }
  

export const getCheckCardholderByPhoneQueryKey = (phoneNumber: string,) => [`/cardholders/checkByPhone/${phoneNumber}`];

    
export type CheckCardholderByPhoneQueryResult = NonNullable<Awaited<ReturnType<typeof checkCardholderByPhone>>>
export type CheckCardholderByPhoneQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useCheckCardholderByPhone = <TData = Awaited<ReturnType<typeof checkCardholderByPhone>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 phoneNumber: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof checkCardholderByPhone>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCheckCardholderByPhoneQueryKey(phoneNumber);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof checkCardholderByPhone>>> = ({ signal }) => checkCardholderByPhone(phoneNumber, signal);

  const query = useQuery<Awaited<ReturnType<typeof checkCardholderByPhone>>, TError, TData>(queryKey, queryFn, {enabled: !!(phoneNumber), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Sends the cardholder an email with a link to reset their password. Optionally pass in a language code to send the email in the given language. We currently only support Spanish ('es').
 * @summary Send Cardholder Password Reset
 */
export const sendResetCardholderPasswordEmail = (
    sendResetCardholderPasswordEmailParams: SendResetCardholderPasswordEmailParams,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/cardholders/passwordReset`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sendResetCardholderPasswordEmailParams
    },
      );
    }
  


    export type SendResetCardholderPasswordEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sendResetCardholderPasswordEmail>>>
    export type SendResetCardholderPasswordEmailMutationBody = SendResetCardholderPasswordEmailParams
    export type SendResetCardholderPasswordEmailMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSendResetCardholderPasswordEmail = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendResetCardholderPasswordEmail>>, TError,{data: SendResetCardholderPasswordEmailParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendResetCardholderPasswordEmail>>, {data: SendResetCardholderPasswordEmailParams}> = (props) => {
          const {data} = props ?? {};

          return  sendResetCardholderPasswordEmail(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sendResetCardholderPasswordEmail>>, TError, {data: SendResetCardholderPasswordEmailParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the given cardholder by id
 * @summary Get Cardholder
 */
export const getCardholder = (
    cardholderId: string,
 signal?: AbortSignal
) => {
      return client<GetCardholderResponseResponse>(
      {url: `/cardholders/${cardholderId}/`, method: 'get', signal
    },
      );
    }
  

export const getGetCardholderQueryKey = (cardholderId: string,) => [`/cardholders/${cardholderId}/`];

    
export type GetCardholderQueryResult = NonNullable<Awaited<ReturnType<typeof getCardholder>>>
export type GetCardholderQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardholder = <TData = Awaited<ReturnType<typeof getCardholder>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardholderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardholder>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardholderQueryKey(cardholderId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardholder>>> = ({ signal }) => getCardholder(cardholderId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardholder>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardholderId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Updates cardholder with new reference id and activates virtual card
 * @summary Activate Virtual Card
 */
export const activateVirtualCard = (
    cardholderId: string,
    activateVirtualCardParams: ActivateVirtualCardParams,
 ) => {
      return client<ActivateVirtualCardResponseResponse>(
      {url: `/cardholders/${cardholderId}/activateVirtualCard`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: activateVirtualCardParams
    },
      );
    }
  


    export type ActivateVirtualCardMutationResult = NonNullable<Awaited<ReturnType<typeof activateVirtualCard>>>
    export type ActivateVirtualCardMutationBody = ActivateVirtualCardParams
    export type ActivateVirtualCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useActivateVirtualCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof activateVirtualCard>>, TError,{cardholderId: string;data: ActivateVirtualCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof activateVirtualCard>>, {cardholderId: string;data: ActivateVirtualCardParams}> = (props) => {
          const {cardholderId,data} = props ?? {};

          return  activateVirtualCard(cardholderId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof activateVirtualCard>>, TError, {cardholderId: string;data: ActivateVirtualCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Attaches a card to a cardholder
 * @summary Link Cardholder to Card
 */
export const linkCardholderToCard = (
    cardholderId: string,
    linkCardholderToCardParams: LinkCardholderToCardParams,
 ) => {
      return client<LinkCardholderToCardResponseResponse>(
      {url: `/cardholders/${cardholderId}/card`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: linkCardholderToCardParams
    },
      );
    }
  


    export type LinkCardholderToCardMutationResult = NonNullable<Awaited<ReturnType<typeof linkCardholderToCard>>>
    export type LinkCardholderToCardMutationBody = LinkCardholderToCardParams
    export type LinkCardholderToCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useLinkCardholderToCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof linkCardholderToCard>>, TError,{cardholderId: string;data: LinkCardholderToCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof linkCardholderToCard>>, {cardholderId: string;data: LinkCardholderToCardParams}> = (props) => {
          const {cardholderId,data} = props ?? {};

          return  linkCardholderToCard(cardholderId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof linkCardholderToCard>>, TError, {cardholderId: string;data: LinkCardholderToCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns a cardholder's communication preferences
 * @summary Get Cardholder Communication Preferences
 */
export const getCardholderCommunicationPreferences = (
    cardholderId: string,
 signal?: AbortSignal
) => {
      return client<GetCardholderCommunicationPreferencesResponseResponse>(
      {url: `/cardholders/${cardholderId}/communicationPreferences`, method: 'get', signal
    },
      );
    }
  

export const getGetCardholderCommunicationPreferencesQueryKey = (cardholderId: string,) => [`/cardholders/${cardholderId}/communicationPreferences`];

    
export type GetCardholderCommunicationPreferencesQueryResult = NonNullable<Awaited<ReturnType<typeof getCardholderCommunicationPreferences>>>
export type GetCardholderCommunicationPreferencesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardholderCommunicationPreferences = <TData = Awaited<ReturnType<typeof getCardholderCommunicationPreferences>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardholderId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardholderCommunicationPreferences>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardholderCommunicationPreferencesQueryKey(cardholderId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardholderCommunicationPreferences>>> = ({ signal }) => getCardholderCommunicationPreferences(cardholderId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardholderCommunicationPreferences>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardholderId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Updates cardholder communication preferences
 * @summary Update Cardholder Communication Preferences
 */
export const updateCardholderCommunicationPreferences = (
    cardholderId: string,
    updateCardholderCommunicationPreferencesParams: UpdateCardholderCommunicationPreferencesParams,
 ) => {
      return client<UpdateCardholderCommunicationPreferencesResponseResponse>(
      {url: `/cardholders/${cardholderId}/communicationPreferences`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateCardholderCommunicationPreferencesParams
    },
      );
    }
  


    export type UpdateCardholderCommunicationPreferencesMutationResult = NonNullable<Awaited<ReturnType<typeof updateCardholderCommunicationPreferences>>>
    export type UpdateCardholderCommunicationPreferencesMutationBody = UpdateCardholderCommunicationPreferencesParams
    export type UpdateCardholderCommunicationPreferencesMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUpdateCardholderCommunicationPreferences = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateCardholderCommunicationPreferences>>, TError,{cardholderId: string;data: UpdateCardholderCommunicationPreferencesParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateCardholderCommunicationPreferences>>, {cardholderId: string;data: UpdateCardholderCommunicationPreferencesParams}> = (props) => {
          const {cardholderId,data} = props ?? {};

          return  updateCardholderCommunicationPreferences(cardholderId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateCardholderCommunicationPreferences>>, TError, {cardholderId: string;data: UpdateCardholderCommunicationPreferencesParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Initiates a card to card fund transfer.
 * @summary Card to Card Transfer
 */
export const cardToCardTransfer = (
    businessId: string,
    cardToCardTransferParams: CardToCardTransferParams,
 ) => {
      return client<CardToCardTransferResponseResponse>(
      {url: `/cards/business/${businessId}/cardToCardTransfer`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: cardToCardTransferParams
    },
      );
    }
  


    export type CardToCardTransferMutationResult = NonNullable<Awaited<ReturnType<typeof cardToCardTransfer>>>
    export type CardToCardTransferMutationBody = CardToCardTransferParams
    export type CardToCardTransferMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCardToCardTransfer = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof cardToCardTransfer>>, TError,{businessId: string;data: CardToCardTransferParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof cardToCardTransfer>>, {businessId: string;data: CardToCardTransferParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  cardToCardTransfer(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof cardToCardTransfer>>, TError, {businessId: string;data: CardToCardTransferParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all cards issued by a business. Optionally filter and sort using query param options.
 * @summary Get Cards
 */
export const getCards = (
    businessId: string,
    params?: GetCardsParams,
 signal?: AbortSignal
) => {
      return client<GetCardsResponseResponse>(
      {url: `/cards/business/${businessId}/cards`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetCardsQueryKey = (businessId: string,
    params?: GetCardsParams,) => [`/cards/business/${businessId}/cards`, ...(params ? [params]: [])];

    
export type GetCardsQueryResult = NonNullable<Awaited<ReturnType<typeof getCards>>>
export type GetCardsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCards = <TData = Awaited<ReturnType<typeof getCards>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetCardsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCards>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardsQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCards>>> = ({ signal }) => getCards(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCards>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Creates a card and delivers it if a delivery method is specified. If delivery method is 'none', GiveCard creates the card, but the customer is responsible for displaying card details to the customer via Iframe. By issuing a card with 'none' as the deliveryMethod, that cardholder will not be able to access GiveCard's cardholder portal. Currently, this endpoint only supports virtual card issuing.
 * @summary Issue Single Card
 */
export const issueSingleCard = (
    businessId: string,
    issueSingleCardParams: IssueSingleCardParams,
 ) => {
      return client<IssueSingleCardResponseResponse>(
      {url: `/cards/business/${businessId}/issueSingle`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: issueSingleCardParams
    },
      );
    }
  


    export type IssueSingleCardMutationResult = NonNullable<Awaited<ReturnType<typeof issueSingleCard>>>
    export type IssueSingleCardMutationBody = IssueSingleCardParams
    export type IssueSingleCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useIssueSingleCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof issueSingleCard>>, TError,{businessId: string;data: IssueSingleCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof issueSingleCard>>, {businessId: string;data: IssueSingleCardParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  issueSingleCard(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof issueSingleCard>>, TError, {businessId: string;data: IssueSingleCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Loads all given cards with the given amount of money. Optionally add metadata to the load records.
 * @summary Load Cards
 */
export const loadCards = (
    businessId: string,
    loadCardsParams: LoadCardsParams,
 ) => {
      return client<LoadCardsResponseResponse>(
      {url: `/cards/business/${businessId}/load`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: loadCardsParams
    },
      );
    }
  


    export type LoadCardsMutationResult = NonNullable<Awaited<ReturnType<typeof loadCards>>>
    export type LoadCardsMutationBody = LoadCardsParams
    export type LoadCardsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useLoadCards = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof loadCards>>, TError,{businessId: string;data: LoadCardsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof loadCards>>, {businessId: string;data: LoadCardsParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  loadCards(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof loadCards>>, TError, {businessId: string;data: LoadCardsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Orders and Ships x Cards to the given address. Optionally add a merchant rule by id, tags, and card personalizations on order.
 * @summary Order and Ship Cards
 */
export const orderAndShipCards = (
    businessId: string,
    orderAndShipCardsParams: OrderAndShipCardsParams,
 ) => {
      return client<OrderAndShipCardsResponseResponse>(
      {url: `/cards/business/${businessId}/orderAndShip`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: orderAndShipCardsParams
    },
      );
    }
  


    export type OrderAndShipCardsMutationResult = NonNullable<Awaited<ReturnType<typeof orderAndShipCards>>>
    export type OrderAndShipCardsMutationBody = OrderAndShipCardsParams
    export type OrderAndShipCardsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useOrderAndShipCards = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orderAndShipCards>>, TError,{businessId: string;data: OrderAndShipCardsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orderAndShipCards>>, {businessId: string;data: OrderAndShipCardsParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  orderAndShipCards(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof orderAndShipCards>>, TError, {businessId: string;data: OrderAndShipCardsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the available prefunding balance for a business in USD.
 * @summary Get Business's Prefunding Balance
 */
export const getBusinessPrefundingBalance = (
    businessId: string,
    params?: GetBusinessPrefundingBalanceParams,
 signal?: AbortSignal
) => {
      return client<GetBusinessPrefundingBalanceResponseResponse>(
      {url: `/cards/business/${businessId}/prefundingBalance`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetBusinessPrefundingBalanceQueryKey = (businessId: string,
    params?: GetBusinessPrefundingBalanceParams,) => [`/cards/business/${businessId}/prefundingBalance`, ...(params ? [params]: [])];

    
export type GetBusinessPrefundingBalanceQueryResult = NonNullable<Awaited<ReturnType<typeof getBusinessPrefundingBalance>>>
export type GetBusinessPrefundingBalanceQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBusinessPrefundingBalance = <TData = Awaited<ReturnType<typeof getBusinessPrefundingBalance>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string,
    params?: GetBusinessPrefundingBalanceParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBusinessPrefundingBalance>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBusinessPrefundingBalanceQueryKey(businessId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBusinessPrefundingBalance>>> = ({ signal }) => getBusinessPrefundingBalance(businessId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBusinessPrefundingBalance>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Adds / creates a tag to be applied the given array of cards.
 * @summary Add Tag To Cards
 */
export const addTagToCards = (
    businessId: string,
    addTagToCardsParams: AddTagToCardsParams,
 ) => {
      return client<AddTagToCardsResponseResponse>(
      {url: `/cards/business/${businessId}/tag`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addTagToCardsParams
    },
      );
    }
  


    export type AddTagToCardsMutationResult = NonNullable<Awaited<ReturnType<typeof addTagToCards>>>
    export type AddTagToCardsMutationBody = AddTagToCardsParams
    export type AddTagToCardsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useAddTagToCards = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addTagToCards>>, TError,{businessId: string;data: AddTagToCardsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addTagToCards>>, {businessId: string;data: AddTagToCardsParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  addTagToCards(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addTagToCards>>, TError, {businessId: string;data: AddTagToCardsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Sends reminder emails for the given virtual cards, or sends to all unactivated cards associated with the business if cardIds is null
 * @summary Send Virtual Card Reminders
 */
export const sendVirtualCardReminders = (
    businessId: string,
    issueVirtualCardsParamsBody: IssueVirtualCardsParamsBody,
 ) => {
      return client<SendVirtualCardRemindersResponseResponse>(
      {url: `/cards/business/${businessId}/virtualCardReminders`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: issueVirtualCardsParamsBody
    },
      );
    }
  


    export type SendVirtualCardRemindersMutationResult = NonNullable<Awaited<ReturnType<typeof sendVirtualCardReminders>>>
    export type SendVirtualCardRemindersMutationBody = IssueVirtualCardsParamsBody
    export type SendVirtualCardRemindersMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSendVirtualCardReminders = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendVirtualCardReminders>>, TError,{businessId: string;data: IssueVirtualCardsParamsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendVirtualCardReminders>>, {businessId: string;data: IssueVirtualCardsParamsBody}> = (props) => {
          const {businessId,data} = props ?? {};

          return  sendVirtualCardReminders(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof sendVirtualCardReminders>>, TError, {businessId: string;data: IssueVirtualCardsParamsBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates and delivers virtual cards to the given email addresses. Optionally add a merchant rule by id, an initial load, and tags upon order.
 * @summary Issue Virtual Cards
 */
export const issueVirtualCards = (
    businessId: string,
    issueVirtualCardsParamsBody: IssueVirtualCardsParamsBody,
 ) => {
      return client<IssueVirtualCardsResponseResponse>(
      {url: `/cards/business/${businessId}/virtualCards`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: issueVirtualCardsParamsBody
    },
      );
    }
  


    export type IssueVirtualCardsMutationResult = NonNullable<Awaited<ReturnType<typeof issueVirtualCards>>>
    export type IssueVirtualCardsMutationBody = IssueVirtualCardsParamsBody
    export type IssueVirtualCardsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useIssueVirtualCards = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof issueVirtualCards>>, TError,{businessId: string;data: IssueVirtualCardsParamsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof issueVirtualCards>>, {businessId: string;data: IssueVirtualCardsParamsBody}> = (props) => {
          const {businessId,data} = props ?? {};

          return  issueVirtualCards(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof issueVirtualCards>>, TError, {businessId: string;data: IssueVirtualCardsParamsBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Attempts to match an input to a given card, given the last4 digits as well as the expiration date (in format MM/YY)
 * @summary Match Card Info
 */
export const matchCardInfo = (
    matchCardInfoParams: MatchCardInfoParams,
 ) => {
      return client<MatchCardInfoResponseResponse>(
      {url: `/cards/matchInfo`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: matchCardInfoParams
    },
      );
    }
  


    export type MatchCardInfoMutationResult = NonNullable<Awaited<ReturnType<typeof matchCardInfo>>>
    export type MatchCardInfoMutationBody = MatchCardInfoParams
    export type MatchCardInfoMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useMatchCardInfo = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof matchCardInfo>>, TError,{data: MatchCardInfoParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof matchCardInfo>>, {data: MatchCardInfoParams}> = (props) => {
          const {data} = props ?? {};

          return  matchCardInfo(data,)
        }

      return useMutation<Awaited<ReturnType<typeof matchCardInfo>>, TError, {data: MatchCardInfoParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Removes a merchant rule from given cards
 * @summary Remove Merchant Rule from Cards
 */
export const removeMerchantRuleFromCards = (
    removeMerchantRuleFromCardsParams: RemoveMerchantRuleFromCardsParams,
 ) => {
      return client<RemoveMerchantRuleFromCardsResponseResponse>(
      {url: `/cards/merchantRule`, method: 'delete',
      headers: {'Content-Type': 'application/json', },
      data: removeMerchantRuleFromCardsParams
    },
      );
    }
  


    export type RemoveMerchantRuleFromCardsMutationResult = NonNullable<Awaited<ReturnType<typeof removeMerchantRuleFromCards>>>
    export type RemoveMerchantRuleFromCardsMutationBody = RemoveMerchantRuleFromCardsParams
    export type RemoveMerchantRuleFromCardsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useRemoveMerchantRuleFromCards = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof removeMerchantRuleFromCards>>, TError,{data: RemoveMerchantRuleFromCardsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof removeMerchantRuleFromCards>>, {data: RemoveMerchantRuleFromCardsParams}> = (props) => {
          const {data} = props ?? {};

          return  removeMerchantRuleFromCards(data,)
        }

      return useMutation<Awaited<ReturnType<typeof removeMerchantRuleFromCards>>, TError, {data: RemoveMerchantRuleFromCardsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Attaches a merchant category rule to given cards
 * @summary Add Merchant Rule to Cards
 */
export const addMerchantRuleToCards = (
    merchantRuleId: string,
    addMerchantRuleToCardsParams: AddMerchantRuleToCardsParams,
 ) => {
      return client<AddMerchantRuleToCardsResponseResponse>(
      {url: `/cards/merchantRule/${merchantRuleId}/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addMerchantRuleToCardsParams
    },
      );
    }
  


    export type AddMerchantRuleToCardsMutationResult = NonNullable<Awaited<ReturnType<typeof addMerchantRuleToCards>>>
    export type AddMerchantRuleToCardsMutationBody = AddMerchantRuleToCardsParams
    export type AddMerchantRuleToCardsMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useAddMerchantRuleToCards = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addMerchantRuleToCards>>, TError,{merchantRuleId: string;data: AddMerchantRuleToCardsParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addMerchantRuleToCards>>, {merchantRuleId: string;data: AddMerchantRuleToCardsParams}> = (props) => {
          const {merchantRuleId,data} = props ?? {};

          return  addMerchantRuleToCards(merchantRuleId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addMerchantRuleToCards>>, TError, {merchantRuleId: string;data: AddMerchantRuleToCardsParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Fetches all activities on a card.
 * @summary Get Card Activities
 */
export const getCardActivities = (
    cardId: string,
 signal?: AbortSignal
) => {
      return client<GetCardActivitiesResponseBody>(
      {url: `/cards/${cardId}/activities`, method: 'get', signal
    },
      );
    }
  

export const getGetCardActivitiesQueryKey = (cardId: string,) => [`/cards/${cardId}/activities`];

    
export type GetCardActivitiesQueryResult = NonNullable<Awaited<ReturnType<typeof getCardActivities>>>
export type GetCardActivitiesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardActivities = <TData = Awaited<ReturnType<typeof getCardActivities>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardActivities>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardActivitiesQueryKey(cardId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardActivities>>> = ({ signal }) => getCardActivities(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardActivities>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Gets the available balance for a given card in USD
 * @summary Get Card Balance
 */
export const getCardBalance = (
    cardId: string,
 signal?: AbortSignal
) => {
      return client<GetCardBalanceResponseResponse>(
      {url: `/cards/${cardId}/balance`, method: 'get', signal
    },
      );
    }
  

export const getGetCardBalanceQueryKey = (cardId: string,) => [`/cards/${cardId}/balance`];

    
export type GetCardBalanceQueryResult = NonNullable<Awaited<ReturnType<typeof getCardBalance>>>
export type GetCardBalanceQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardBalance = <TData = Awaited<ReturnType<typeof getCardBalance>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardBalance>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardBalanceQueryKey(cardId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardBalance>>> = ({ signal }) => getCardBalance(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardBalance>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Fetches a client token in order to view full card details via Highnote SDK
 * @summary Get Payment Card Client Token
 */
export const getPaymentCardClientToken = (
    cardId: string,
 signal?: AbortSignal
) => {
      return client<GetPaymentCardClientTokenResponseBody>(
      {url: `/cards/${cardId}/clientToken`, method: 'get', signal
    },
      );
    }
  

export const getGetPaymentCardClientTokenQueryKey = (cardId: string,) => [`/cards/${cardId}/clientToken`];

    
export type GetPaymentCardClientTokenQueryResult = NonNullable<Awaited<ReturnType<typeof getPaymentCardClientToken>>>
export type GetPaymentCardClientTokenQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetPaymentCardClientToken = <TData = Awaited<ReturnType<typeof getPaymentCardClientToken>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPaymentCardClientToken>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPaymentCardClientTokenQueryKey(cardId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPaymentCardClientToken>>> = ({ signal }) => getPaymentCardClientToken(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPaymentCardClientToken>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Closes a card and transfers funds back to the funding account. Include an admin id in the request body to record the user who closed the card on the transfer.
 * @summary Close Card
 */
export const closeCard = (
    cardId: string,
    closeCardParams: CloseCardParams,
 ) => {
      return client<CloseCardResponseBody>(
      {url: `/cards/${cardId}/close`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: closeCardParams
    },
      );
    }
  


    export type CloseCardMutationResult = NonNullable<Awaited<ReturnType<typeof closeCard>>>
    export type CloseCardMutationBody = CloseCardParams
    export type CloseCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCloseCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof closeCard>>, TError,{cardId: string;data: CloseCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof closeCard>>, {cardId: string;data: CloseCardParams}> = (props) => {
          const {cardId,data} = props ?? {};

          return  closeCard(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof closeCard>>, TError, {cardId: string;data: CloseCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns the card object, the card's current balance, transactions, and card order. Optionally include declined transactions by setting the showDeclines flag.
 * @summary Get Card Details
 */
export const getCardDetails = (
    cardId: string,
    params?: GetCardDetailsParams,
 signal?: AbortSignal
) => {
      return client<GetCardDetailsResponseResponse>(
      {url: `/cards/${cardId}/details`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetCardDetailsQueryKey = (cardId: string,
    params?: GetCardDetailsParams,) => [`/cards/${cardId}/details`, ...(params ? [params]: [])];

    
export type GetCardDetailsQueryResult = NonNullable<Awaited<ReturnType<typeof getCardDetails>>>
export type GetCardDetailsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardDetails = <TData = Awaited<ReturnType<typeof getCardDetails>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string,
    params?: GetCardDetailsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardDetails>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardDetailsQueryKey(cardId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardDetails>>> = ({ signal }) => getCardDetails(cardId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardDetails>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Freezes a given card. Card cannot be spent until an administrator unfreezes it.
 * @summary Freeze Card
 */
export const freezeCard = (
    cardId: string,
 ) => {
      return client<FreezeCardResponseResponse>(
      {url: `/cards/${cardId}/freeze`, method: 'post'
    },
      );
    }
  


    export type FreezeCardMutationResult = NonNullable<Awaited<ReturnType<typeof freezeCard>>>
    
    export type FreezeCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useFreezeCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof freezeCard>>, TError,{cardId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof freezeCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  freezeCard(cardId,)
        }

      return useMutation<Awaited<ReturnType<typeof freezeCard>>, TError, {cardId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Get all financial account loads for a given card, takes an optional date range in the RFC3339 format YYYY-MM-DDThh:mm:ssZ.
 * @summary Get Card's Load History
 */
export const getCardLoadHistory = (
    cardId: string,
    params?: GetCardLoadHistoryParams,
 signal?: AbortSignal
) => {
      return client<GetCardLoadHistoryResponseResponse>(
      {url: `/cards/${cardId}/loadHistory`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetCardLoadHistoryQueryKey = (cardId: string,
    params?: GetCardLoadHistoryParams,) => [`/cards/${cardId}/loadHistory`, ...(params ? [params]: [])];

    
export type GetCardLoadHistoryQueryResult = NonNullable<Awaited<ReturnType<typeof getCardLoadHistory>>>
export type GetCardLoadHistoryQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardLoadHistory = <TData = Awaited<ReturnType<typeof getCardLoadHistory>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string,
    params?: GetCardLoadHistoryParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardLoadHistory>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardLoadHistoryQueryKey(cardId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardLoadHistory>>> = ({ signal }) => getCardLoadHistory(cardId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardLoadHistory>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Locks a given active card
 * @summary Lock Card
 */
export const lockCard = (
    cardId: string,
 ) => {
      return client<LockCardResponseResponse>(
      {url: `/cards/${cardId}/lock`, method: 'post'
    },
      );
    }
  


    export type LockCardMutationResult = NonNullable<Awaited<ReturnType<typeof lockCard>>>
    
    export type LockCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useLockCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof lockCard>>, TError,{cardId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof lockCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  lockCard(cardId,)
        }

      return useMutation<Awaited<ReturnType<typeof lockCard>>, TError, {cardId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Sets the pin for a given card. Pin must be 4 digits.
 * @summary Set Pin
 */
export const setCardPin = (
    cardId: string,
    setCardPinParamsBody: SetCardPinParamsBody,
 ) => {
      return client<SetCardPinResponseResponse>(
      {url: `/cards/${cardId}/pin`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: setCardPinParamsBody
    },
      );
    }
  


    export type SetCardPinMutationResult = NonNullable<Awaited<ReturnType<typeof setCardPin>>>
    export type SetCardPinMutationBody = SetCardPinParamsBody
    export type SetCardPinMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSetCardPin = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setCardPin>>, TError,{cardId: string;data: SetCardPinParamsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setCardPin>>, {cardId: string;data: SetCardPinParamsBody}> = (props) => {
          const {cardId,data} = props ?? {};

          return  setCardPin(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof setCardPin>>, TError, {cardId: string;data: SetCardPinParamsBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Reassociates a card.
 * @summary Reassociate Card
 */
export const reassociateCard = (
    cardId: string,
    reassociateCardParams: ReassociateCardParams,
 ) => {
      return client<ReassociateCardResponseBody>(
      {url: `/cards/${cardId}/reassociate`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reassociateCardParams
    },
      );
    }
  


    export type ReassociateCardMutationResult = NonNullable<Awaited<ReturnType<typeof reassociateCard>>>
    export type ReassociateCardMutationBody = ReassociateCardParams
    export type ReassociateCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useReassociateCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reassociateCard>>, TError,{cardId: string;data: ReassociateCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reassociateCard>>, {cardId: string;data: ReassociateCardParams}> = (props) => {
          const {cardId,data} = props ?? {};

          return  reassociateCard(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof reassociateCard>>, TError, {cardId: string;data: ReassociateCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Reissues a card to the same financial account and ships it to the given address
 * @summary Reissue and Ship Card
 */
export const reissueAndShipCard = (
    cardId: string,
    reissueAndShipCardParams: ReissueAndShipCardParams,
 ) => {
      return client<ReissueAndShipCardResponseResponse>(
      {url: `/cards/${cardId}/reissueAndShip`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: reissueAndShipCardParams
    },
      );
    }
  


    export type ReissueAndShipCardMutationResult = NonNullable<Awaited<ReturnType<typeof reissueAndShipCard>>>
    export type ReissueAndShipCardMutationBody = ReissueAndShipCardParams
    export type ReissueAndShipCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useReissueAndShipCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reissueAndShipCard>>, TError,{cardId: string;data: ReissueAndShipCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reissueAndShipCard>>, {cardId: string;data: ReissueAndShipCardParams}> = (props) => {
          const {cardId,data} = props ?? {};

          return  reissueAndShipCard(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof reissueAndShipCard>>, TError, {cardId: string;data: ReissueAndShipCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Reissues a card to the same financial account
 * @summary Reissue Virtual Card
 */
export const reissueVirtualCard = (
    cardId: string,
 ) => {
      return client<ReissueVirtualCardResponseResponse>(
      {url: `/cards/${cardId}/reissueVirtualCard`, method: 'post'
    },
      );
    }
  


    export type ReissueVirtualCardMutationResult = NonNullable<Awaited<ReturnType<typeof reissueVirtualCard>>>
    
    export type ReissueVirtualCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useReissueVirtualCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof reissueVirtualCard>>, TError,{cardId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof reissueVirtualCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  reissueVirtualCard(cardId,)
        }

      return useMutation<Awaited<ReturnType<typeof reissueVirtualCard>>, TError, {cardId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Resend a virtual card email. An activated card cannot be resent.
 * @summary Resend Virtual Card
 */
export const resendVirtualCard = (
    cardId: string,
 ) => {
      return client<ResendVirtualCardResponseResponse>(
      {url: `/cards/${cardId}/resendVirtualCard`, method: 'post'
    },
      );
    }
  


    export type ResendVirtualCardMutationResult = NonNullable<Awaited<ReturnType<typeof resendVirtualCard>>>
    
    export type ResendVirtualCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useResendVirtualCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resendVirtualCard>>, TError,{cardId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resendVirtualCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  resendVirtualCard(cardId,)
        }

      return useMutation<Awaited<ReturnType<typeof resendVirtualCard>>, TError, {cardId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets all the spend rules for a card
 * @summary Get Spend Rules on Card
 */
export const getSpendRulesOnCard = (
    cardId: string,
 signal?: AbortSignal
) => {
      return client<GetSpendRulesOnCardResponseResponse>(
      {url: `/cards/${cardId}/spendRules`, method: 'get', signal
    },
      );
    }
  

export const getGetSpendRulesOnCardQueryKey = (cardId: string,) => [`/cards/${cardId}/spendRules`];

    
export type GetSpendRulesOnCardQueryResult = NonNullable<Awaited<ReturnType<typeof getSpendRulesOnCard>>>
export type GetSpendRulesOnCardQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetSpendRulesOnCard = <TData = Awaited<ReturnType<typeof getSpendRulesOnCard>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getSpendRulesOnCard>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSpendRulesOnCardQueryKey(cardId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSpendRulesOnCard>>> = ({ signal }) => getSpendRulesOnCard(cardId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getSpendRulesOnCard>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Removes a given tag by id from a given card
 * @summary Delete Tag From Card
 */
export const deleteTagFromCard = (
    cardId: string,
    tagId: string,
 ) => {
      return client<DeleteTagFromCardResponseResponse>(
      {url: `/cards/${cardId}/tag/${tagId}/`, method: 'delete'
    },
      );
    }
  


    export type DeleteTagFromCardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTagFromCard>>>
    
    export type DeleteTagFromCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useDeleteTagFromCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTagFromCard>>, TError,{cardId: string;tagId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTagFromCard>>, {cardId: string;tagId: string}> = (props) => {
          const {cardId,tagId} = props ?? {};

          return  deleteTagFromCard(cardId,tagId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteTagFromCard>>, TError, {cardId: string;tagId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Returns all auth, cleared, and refunded transactions for a given card. Optionally include declined transactions by setting the showDeclines flag.
 * @summary Get Card Transactions
 */
export const getCardTransactions = (
    cardId: string,
    params?: GetCardTransactionsParams,
 signal?: AbortSignal
) => {
      return client<GetCardTransactionsResponseResponse>(
      {url: `/cards/${cardId}/transactions`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetCardTransactionsQueryKey = (cardId: string,
    params?: GetCardTransactionsParams,) => [`/cards/${cardId}/transactions`, ...(params ? [params]: [])];

    
export type GetCardTransactionsQueryResult = NonNullable<Awaited<ReturnType<typeof getCardTransactions>>>
export type GetCardTransactionsQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetCardTransactions = <TData = Awaited<ReturnType<typeof getCardTransactions>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 cardId: string,
    params?: GetCardTransactionsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getCardTransactions>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetCardTransactionsQueryKey(cardId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getCardTransactions>>> = ({ signal }) => getCardTransactions(cardId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getCardTransactions>>, TError, TData>(queryKey, queryFn, {enabled: !!(cardId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Sets the pin for a given unauthenticated card, this endpoint is called only for account-less cards.
 * @summary Set Card Pin Unauthenticated
 */
export const setUnauthenticatedCardPin = (
    cardId: string,
    setCardPinParamsBody: SetCardPinParamsBody,
 ) => {
      return client<SetCardPinResponseResponse>(
      {url: `/cards/${cardId}/unauthenticatedPin`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: setCardPinParamsBody
    },
      );
    }
  


    export type SetUnauthenticatedCardPinMutationResult = NonNullable<Awaited<ReturnType<typeof setUnauthenticatedCardPin>>>
    export type SetUnauthenticatedCardPinMutationBody = SetCardPinParamsBody
    export type SetUnauthenticatedCardPinMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSetUnauthenticatedCardPin = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof setUnauthenticatedCardPin>>, TError,{cardId: string;data: SetCardPinParamsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof setUnauthenticatedCardPin>>, {cardId: string;data: SetCardPinParamsBody}> = (props) => {
          const {cardId,data} = props ?? {};

          return  setUnauthenticatedCardPin(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof setUnauthenticatedCardPin>>, TError, {cardId: string;data: SetCardPinParamsBody}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Unfreezes a card.
 * @summary Unfreeze Card
 */
export const unfreezeCard = (
    cardId: string,
 ) => {
      return client<UnfreezeCardResponseResponse>(
      {url: `/cards/${cardId}/unfreeze`, method: 'post'
    },
      );
    }
  


    export type UnfreezeCardMutationResult = NonNullable<Awaited<ReturnType<typeof unfreezeCard>>>
    
    export type UnfreezeCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUnfreezeCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unfreezeCard>>, TError,{cardId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unfreezeCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  unfreezeCard(cardId,)
        }

      return useMutation<Awaited<ReturnType<typeof unfreezeCard>>, TError, {cardId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Unlocks a given locked card
 * @summary Unlock Card
 */
export const unlockCard = (
    cardId: string,
 ) => {
      return client<UnlockCardResponseResponse>(
      {url: `/cards/${cardId}/unlock`, method: 'post'
    },
      );
    }
  


    export type UnlockCardMutationResult = NonNullable<Awaited<ReturnType<typeof unlockCard>>>
    
    export type UnlockCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUnlockCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof unlockCard>>, TError,{cardId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof unlockCard>>, {cardId: string}> = (props) => {
          const {cardId} = props ?? {};

          return  unlockCard(cardId,)
        }

      return useMutation<Awaited<ReturnType<typeof unlockCard>>, TError, {cardId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Withdraws a given amount of funds from a card. The adminId is an optional property that is the firebaseUid for the logged in user performing the request.
 * @summary Withdraw Funds From Card
 */
export const withdrawFundsFromCard = (
    cardId: string,
    withdrawFundsFromCardParams: WithdrawFundsFromCardParams,
 ) => {
      return client<WithdrawFundsFromCardResponseBody>(
      {url: `/cards/${cardId}/withdrawFunds`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: withdrawFundsFromCardParams
    },
      );
    }
  


    export type WithdrawFundsFromCardMutationResult = NonNullable<Awaited<ReturnType<typeof withdrawFundsFromCard>>>
    export type WithdrawFundsFromCardMutationBody = WithdrawFundsFromCardParams
    export type WithdrawFundsFromCardMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useWithdrawFundsFromCard = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof withdrawFundsFromCard>>, TError,{cardId: string;data: WithdrawFundsFromCardParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof withdrawFundsFromCard>>, {cardId: string;data: WithdrawFundsFromCardParams}> = (props) => {
          const {cardId,data} = props ?? {};

          return  withdrawFundsFromCard(cardId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof withdrawFundsFromCard>>, TError, {cardId: string;data: WithdrawFundsFromCardParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Submits info for a beneficial owner. A beneficial owner does not need login credentials to complete this form.
 * @summary Submit Beneficial Owner Info
 */
export const submitBeneficialOwnerInfo = (
    businessId: string,
    beneficialOwnerId: string,
    submitBeneficialOwnerInfoParams: SubmitBeneficialOwnerInfoParams,
 ) => {
      return client<SubmitBeneficialOwnerInfoResponseResponse>(
      {url: `/onboarding/business/${businessId}/beneficialOwner/${beneficialOwnerId}/info`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: submitBeneficialOwnerInfoParams
    },
      );
    }
  


    export type SubmitBeneficialOwnerInfoMutationResult = NonNullable<Awaited<ReturnType<typeof submitBeneficialOwnerInfo>>>
    export type SubmitBeneficialOwnerInfoMutationBody = SubmitBeneficialOwnerInfoParams
    export type SubmitBeneficialOwnerInfoMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSubmitBeneficialOwnerInfo = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitBeneficialOwnerInfo>>, TError,{businessId: string;beneficialOwnerId: string;data: SubmitBeneficialOwnerInfoParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitBeneficialOwnerInfo>>, {businessId: string;beneficialOwnerId: string;data: SubmitBeneficialOwnerInfoParams}> = (props) => {
          const {businessId,beneficialOwnerId,data} = props ?? {};

          return  submitBeneficialOwnerInfo(businessId,beneficialOwnerId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof submitBeneficialOwnerInfo>>, TError, {businessId: string;beneficialOwnerId: string;data: SubmitBeneficialOwnerInfoParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets all beneficial owner ids, names, and whether they have submitted their form
 * @summary Get Beneficial Owners
 */
export const getBeneficialOwners = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetBeneficialOwnersResponseResponse>(
      {url: `/onboarding/business/${businessId}/beneficialOwners`, method: 'get', signal
    },
      );
    }
  

export const getGetBeneficialOwnersQueryKey = (businessId: string,) => [`/onboarding/business/${businessId}/beneficialOwners`];

    
export type GetBeneficialOwnersQueryResult = NonNullable<Awaited<ReturnType<typeof getBeneficialOwners>>>
export type GetBeneficialOwnersQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetBeneficialOwners = <TData = Awaited<ReturnType<typeof getBeneficialOwners>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getBeneficialOwners>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetBeneficialOwnersQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getBeneficialOwners>>> = ({ signal }) => getBeneficialOwners(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getBeneficialOwners>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Gets the acceptable types for document verification uploads
 * @summary Get Document Upload Link
 */
export const getDocumentUploadLink = (
    businessId: string,
    getDocumentUploadLinkParams: GetDocumentUploadLinkParams,
 ) => {
      return client<GetDocumentUploadLinkResponseResponse>(
      {url: `/onboarding/business/${businessId}/documentUpload/link`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: getDocumentUploadLinkParams
    },
      );
    }
  


    export type GetDocumentUploadLinkMutationResult = NonNullable<Awaited<ReturnType<typeof getDocumentUploadLink>>>
    export type GetDocumentUploadLinkMutationBody = GetDocumentUploadLinkParams
    export type GetDocumentUploadLinkMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useGetDocumentUploadLink = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDocumentUploadLink>>, TError,{businessId: string;data: GetDocumentUploadLinkParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDocumentUploadLink>>, {businessId: string;data: GetDocumentUploadLinkParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  getDocumentUploadLink(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof getDocumentUploadLink>>, TError, {businessId: string;data: GetDocumentUploadLinkParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets the acceptable types for document verification uploads
 * @summary Get Document Upload Types
 */
export const getDocumentUploadTypes = (
    businessId: string,
    getDocumentUploadTypesParams: GetDocumentUploadTypesParams,
 ) => {
      return client<GetDocumentUploadTypesResponseBody>(
      {url: `/onboarding/business/${businessId}/documentUpload/types`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: getDocumentUploadTypesParams
    },
      );
    }
  


    export type GetDocumentUploadTypesMutationResult = NonNullable<Awaited<ReturnType<typeof getDocumentUploadTypes>>>
    export type GetDocumentUploadTypesMutationBody = GetDocumentUploadTypesParams
    export type GetDocumentUploadTypesMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useGetDocumentUploadTypes = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof getDocumentUploadTypes>>, TError,{businessId: string;data: GetDocumentUploadTypesParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof getDocumentUploadTypes>>, {businessId: string;data: GetDocumentUploadTypesParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  getDocumentUploadTypes(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof getDocumentUploadTypes>>, TError, {businessId: string;data: GetDocumentUploadTypesParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets the primary authorized person contact information and the status of the form.
 * @summary Get Primary Authorized Person
 */
export const getPrimaryAuthorizedPerson = (
    businessId: string,
 signal?: AbortSignal
) => {
      return client<GetPrimaryAuthorizedPersonResponseResponse>(
      {url: `/onboarding/business/${businessId}/primaryPerson`, method: 'get', signal
    },
      );
    }
  

export const getGetPrimaryAuthorizedPersonQueryKey = (businessId: string,) => [`/onboarding/business/${businessId}/primaryPerson`];

    
export type GetPrimaryAuthorizedPersonQueryResult = NonNullable<Awaited<ReturnType<typeof getPrimaryAuthorizedPerson>>>
export type GetPrimaryAuthorizedPersonQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetPrimaryAuthorizedPerson = <TData = Awaited<ReturnType<typeof getPrimaryAuthorizedPerson>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 businessId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getPrimaryAuthorizedPerson>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetPrimaryAuthorizedPersonQueryKey(businessId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getPrimaryAuthorizedPerson>>> = ({ signal }) => getPrimaryAuthorizedPerson(businessId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getPrimaryAuthorizedPerson>>, TError, TData>(queryKey, queryFn, {enabled: !!(businessId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Elects a person other than the user filling out onboarding as the primary authorized user and emails them to complete the form.
 * @summary Add Primary Authorized Person
 */
export const addPrimaryAuthorizedPerson = (
    businessId: string,
    addPrimaryAuthorizedPersonParams: AddPrimaryAuthorizedPersonParams,
 ) => {
      return client<AddPrimaryAuthorizedPersonResponseResponse>(
      {url: `/onboarding/business/${businessId}/primaryPerson`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: addPrimaryAuthorizedPersonParams
    },
      );
    }
  


    export type AddPrimaryAuthorizedPersonMutationResult = NonNullable<Awaited<ReturnType<typeof addPrimaryAuthorizedPerson>>>
    export type AddPrimaryAuthorizedPersonMutationBody = AddPrimaryAuthorizedPersonParams
    export type AddPrimaryAuthorizedPersonMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useAddPrimaryAuthorizedPerson = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addPrimaryAuthorizedPerson>>, TError,{businessId: string;data: AddPrimaryAuthorizedPersonParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addPrimaryAuthorizedPerson>>, {businessId: string;data: AddPrimaryAuthorizedPersonParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  addPrimaryAuthorizedPerson(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof addPrimaryAuthorizedPerson>>, TError, {businessId: string;data: AddPrimaryAuthorizedPersonParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates the primary authorized person's information. This information is the personal information of an authorized signer of the entity.
 * @summary Submit Primary Authorized Person
 */
export const submitPrimaryAuthorizedPerson = (
    businessId: string,
    submitPrimaryAuthorizedPersonParams: SubmitPrimaryAuthorizedPersonParams,
 ) => {
      return client<SubmitPrimaryAuthorizedPersonResponseResponse>(
      {url: `/onboarding/business/${businessId}/primaryPerson`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: submitPrimaryAuthorizedPersonParams
    },
      );
    }
  


    export type SubmitPrimaryAuthorizedPersonMutationResult = NonNullable<Awaited<ReturnType<typeof submitPrimaryAuthorizedPerson>>>
    export type SubmitPrimaryAuthorizedPersonMutationBody = SubmitPrimaryAuthorizedPersonParams
    export type SubmitPrimaryAuthorizedPersonMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSubmitPrimaryAuthorizedPerson = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitPrimaryAuthorizedPerson>>, TError,{businessId: string;data: SubmitPrimaryAuthorizedPersonParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitPrimaryAuthorizedPerson>>, {businessId: string;data: SubmitPrimaryAuthorizedPersonParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  submitPrimaryAuthorizedPerson(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof submitPrimaryAuthorizedPerson>>, TError, {businessId: string;data: SubmitPrimaryAuthorizedPersonParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * If there are beneficial owners: adds the beneficial owners and sends an email to complete the form. If no beneficial owners: submits the application to Highnote
 * @summary Submit Onboarding Application
 */
export const submitOnboardingApplication = (
    businessId: string,
    submitApplicationParams: SubmitApplicationParams,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/onboarding/business/${businessId}/submit`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: submitApplicationParams
    },
      );
    }
  


    export type SubmitOnboardingApplicationMutationResult = NonNullable<Awaited<ReturnType<typeof submitOnboardingApplication>>>
    export type SubmitOnboardingApplicationMutationBody = SubmitApplicationParams
    export type SubmitOnboardingApplicationMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSubmitOnboardingApplication = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof submitOnboardingApplication>>, TError,{businessId: string;data: SubmitApplicationParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof submitOnboardingApplication>>, {businessId: string;data: SubmitApplicationParams}> = (props) => {
          const {businessId,data} = props ?? {};

          return  submitOnboardingApplication(businessId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof submitOnboardingApplication>>, TError, {businessId: string;data: SubmitApplicationParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Ends the provided document upload session, submitting documents to Highnote for review
 * @summary End Upload Session
 */
export const endUploadSession = (
    endDocumentUploadSessionParams: EndDocumentUploadSessionParams,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/onboarding/endUploadSession`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: endDocumentUploadSessionParams
    },
      );
    }
  


    export type EndUploadSessionMutationResult = NonNullable<Awaited<ReturnType<typeof endUploadSession>>>
    export type EndUploadSessionMutationBody = EndDocumentUploadSessionParams
    export type EndUploadSessionMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useEndUploadSession = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof endUploadSession>>, TError,{data: EndDocumentUploadSessionParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof endUploadSession>>, {data: EndDocumentUploadSessionParams}> = (props) => {
          const {data} = props ?? {};

          return  endUploadSession(data,)
        }

      return useMutation<Awaited<ReturnType<typeof endUploadSession>>, TError, {data: EndDocumentUploadSessionParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Calls Highnote's Ping endpoint to verify its working
 * @summary Call Ping
 */
export const callPing = (
    
 signal?: AbortSignal
) => {
      return client<CallPingResponseResponse>(
      {url: `/onboarding/ping`, method: 'get', signal
    },
      );
    }
  

export const getCallPingQueryKey = () => [`/onboarding/ping`];

    
export type CallPingQueryResult = NonNullable<Awaited<ReturnType<typeof callPing>>>
export type CallPingQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useCallPing = <TData = Awaited<ReturnType<typeof callPing>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof callPing>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getCallPingQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof callPing>>> = ({ signal }) => callPing(signal);

  const query = useQuery<Awaited<ReturnType<typeof callPing>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Tells you whether an upload session is ready for submission or has more requirements
 * @summary Get Upload Session Submission Status
 */
export const getUploadSessionSubmissionStatus = (
    params?: GetUploadSessionSubmissionStatusParams,
 signal?: AbortSignal
) => {
      return client<GetUploadSessionSubmissionStatusResponseBody>(
      {url: `/onboarding/uploadSessionSubmissionStatus`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetUploadSessionSubmissionStatusQueryKey = (params?: GetUploadSessionSubmissionStatusParams,) => [`/onboarding/uploadSessionSubmissionStatus`, ...(params ? [params]: [])];

    
export type GetUploadSessionSubmissionStatusQueryResult = NonNullable<Awaited<ReturnType<typeof getUploadSessionSubmissionStatus>>>
export type GetUploadSessionSubmissionStatusQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetUploadSessionSubmissionStatus = <TData = Awaited<ReturnType<typeof getUploadSessionSubmissionStatus>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 params?: GetUploadSessionSubmissionStatusParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUploadSessionSubmissionStatus>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUploadSessionSubmissionStatusQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUploadSessionSubmissionStatus>>> = ({ signal }) => getUploadSessionSubmissionStatus(params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUploadSessionSubmissionStatus>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Creates a new admin user after they've completed authentication. This also creates a business placeholder in this step (just to generate the UUID to attach to the user).
 * @summary Create User
 */
export const createUser = (
    createUserParams: CreateUserParams,
 ) => {
      return client<CreateUserResponseResponse>(
      {url: `/onboarding/user`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createUserParams
    },
      );
    }
  


    export type CreateUserMutationResult = NonNullable<Awaited<ReturnType<typeof createUser>>>
    export type CreateUserMutationBody = CreateUserParams
    export type CreateUserMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateUser = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createUser>>, TError,{data: CreateUserParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createUser>>, {data: CreateUserParams}> = (props) => {
          const {data} = props ?? {};

          return  createUser(data,)
        }

      return useMutation<Awaited<ReturnType<typeof createUser>>, TError, {data: CreateUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Adds all the business information to the initially created business in the CreateUser step.
 * @summary Create Business
 */
export const createBusiness = (
    userId: string,
    createBusinessParams: CreateBusinessParams,
 ) => {
      return client<CreateBusinessResponseResponse>(
      {url: `/onboarding/user/${userId}/business`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createBusinessParams
    },
      );
    }
  


    export type CreateBusinessMutationResult = NonNullable<Awaited<ReturnType<typeof createBusiness>>>
    export type CreateBusinessMutationBody = CreateBusinessParams
    export type CreateBusinessMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateBusiness = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createBusiness>>, TError,{userId: string;data: CreateBusinessParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createBusiness>>, {userId: string;data: CreateBusinessParams}> = (props) => {
          const {userId,data} = props ?? {};

          return  createBusiness(userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createBusiness>>, TError, {userId: string;data: CreateBusinessParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes note
 * @summary Delete Note
 */
export const deleteNote = (
    noteId: string,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/users/notes/${noteId}/`, method: 'delete'
    },
      );
    }
  


    export type DeleteNoteMutationResult = NonNullable<Awaited<ReturnType<typeof deleteNote>>>
    
    export type DeleteNoteMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useDeleteNote = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteNote>>, TError,{noteId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteNote>>, {noteId: string}> = (props) => {
          const {noteId} = props ?? {};

          return  deleteNote(noteId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteNote>>, TError, {noteId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Updates note with new information
 * @summary Update Note
 */
export const updateNote = (
    noteId: string,
    updateNoteParams: UpdateNoteParams,
 ) => {
      return client<UpdateNoteResponseResponse>(
      {url: `/users/notes/${noteId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateNoteParams
    },
      );
    }
  


    export type UpdateNoteMutationResult = NonNullable<Awaited<ReturnType<typeof updateNote>>>
    export type UpdateNoteMutationBody = UpdateNoteParams
    export type UpdateNoteMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUpdateNote = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateNote>>, TError,{noteId: string;data: UpdateNoteParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateNote>>, {noteId: string;data: UpdateNoteParams}> = (props) => {
          const {noteId,data} = props ?? {};

          return  updateNote(noteId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateNote>>, TError, {noteId: string;data: UpdateNoteParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Sends the user an email with a link to reset their password
 * @summary Send Reset Password Email to User
 */
export const sendResetUserPasswordEmail = (
    sendResetUserPasswordEmailParams: SendResetUserPasswordEmailParams,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/users/passwordReset`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: sendResetUserPasswordEmailParams
    },
      );
    }
  


    export type SendResetUserPasswordEmailMutationResult = NonNullable<Awaited<ReturnType<typeof sendResetUserPasswordEmail>>>
    export type SendResetUserPasswordEmailMutationBody = SendResetUserPasswordEmailParams
    export type SendResetUserPasswordEmailMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useSendResetUserPasswordEmail = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof sendResetUserPasswordEmail>>, TError,{data: SendResetUserPasswordEmailParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof sendResetUserPasswordEmail>>, {data: SendResetUserPasswordEmailParams}> = (props) => {
          const {data} = props ?? {};

          return  sendResetUserPasswordEmail(data,)
        }

      return useMutation<Awaited<ReturnType<typeof sendResetUserPasswordEmail>>, TError, {data: SendResetUserPasswordEmailParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Gets a user's login type
 * @summary Get User Login Type
 */
export const getUserLoginType = (
    email: string,
 signal?: AbortSignal
) => {
      return client<GetUserLoginTypeResponseResponse>(
      {url: `/users/${email}/loginType`, method: 'get', signal
    },
      );
    }
  

export const getGetUserLoginTypeQueryKey = (email: string,) => [`/users/${email}/loginType`];

    
export type GetUserLoginTypeQueryResult = NonNullable<Awaited<ReturnType<typeof getUserLoginType>>>
export type GetUserLoginTypeQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetUserLoginType = <TData = Awaited<ReturnType<typeof getUserLoginType>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 email: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUserLoginType>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserLoginTypeQueryKey(email);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserLoginType>>> = ({ signal }) => getUserLoginType(email, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUserLoginType>>, TError, TData>(queryKey, queryFn, {enabled: !!(email), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Gets a user and the corresponding business
 * @summary Get User
 */
export const getUser = (
    userId: string,
 signal?: AbortSignal
) => {
      return client<GetUserResponseResponse>(
      {url: `/users/${userId}/`, method: 'get', signal
    },
      );
    }
  

export const getGetUserQueryKey = (userId: string,) => [`/users/${userId}/`];

    
export type GetUserQueryResult = NonNullable<Awaited<ReturnType<typeof getUser>>>
export type GetUserQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetUser = <TData = Awaited<ReturnType<typeof getUser>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 userId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getUser>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserQueryKey(userId);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUser>>> = ({ signal }) => getUser(userId, signal);

  const query = useQuery<Awaited<ReturnType<typeof getUser>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Updates user with new information
 * @summary Update User
 */
export const updateUser = (
    userId: string,
    updateUserParams: UpdateUserParams,
 ) => {
      return client<UpdateUserResponseResponse>(
      {url: `/users/${userId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateUserParams
    },
      );
    }
  


    export type UpdateUserMutationResult = NonNullable<Awaited<ReturnType<typeof updateUser>>>
    export type UpdateUserMutationBody = UpdateUserParams
    export type UpdateUserMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useUpdateUser = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateUser>>, TError,{userId: string;data: UpdateUserParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateUser>>, {userId: string;data: UpdateUserParams}> = (props) => {
          const {userId,data} = props ?? {};

          return  updateUser(userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof updateUser>>, TError, {userId: string;data: UpdateUserParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Deletes the given user. This will set their status as deleted in the db and remove them from firebase.
 * @summary Delete User
 */
export const deleteUser = (
    userId: string,
 ) => {
      return client<EmptyResponseResponse>(
      {url: `/users/${userId}/`, method: 'delete'
    },
      );
    }
  


    export type DeleteUserMutationResult = NonNullable<Awaited<ReturnType<typeof deleteUser>>>
    
    export type DeleteUserMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useDeleteUser = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteUser>>, TError,{userId: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteUser>>, {userId: string}> = (props) => {
          const {userId} = props ?? {};

          return  deleteUser(userId,)
        }

      return useMutation<Awaited<ReturnType<typeof deleteUser>>, TError, {userId: string}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Creates a note
 * @summary Create Note
 */
export const createNote = (
    userId: string,
    createNoteParams: CreateNoteParams,
 ) => {
      return client<CreateNoteResponseResponse>(
      {url: `/users/${userId}/note`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createNoteParams
    },
      );
    }
  


    export type CreateNoteMutationResult = NonNullable<Awaited<ReturnType<typeof createNote>>>
    export type CreateNoteMutationBody = CreateNoteParams
    export type CreateNoteMutationError = ErrorType<WhimsyErrorResponseResponse>

    export const useCreateNote = <TError = ErrorType<WhimsyErrorResponseResponse>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createNote>>, TError,{userId: string;data: CreateNoteParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createNote>>, {userId: string;data: CreateNoteParams}> = (props) => {
          const {userId,data} = props ?? {};

          return  createNote(userId,data,)
        }

      return useMutation<Awaited<ReturnType<typeof createNote>>, TError, {userId: string;data: CreateNoteParams}, TContext>(mutationFn, mutationOptions)
    }
    
/**
 * Retrieves all notes, both team notes within the business and a user's personal notes.
 * @summary Get Notes
 */
export const getNotes = (
    userId: string,
    params?: GetNotesParams,
 signal?: AbortSignal
) => {
      return client<GetNotesResponseResponse>(
      {url: `/users/${userId}/notes`, method: 'get',
        params, signal
    },
      );
    }
  

export const getGetNotesQueryKey = (userId: string,
    params?: GetNotesParams,) => [`/users/${userId}/notes`, ...(params ? [params]: [])];

    
export type GetNotesQueryResult = NonNullable<Awaited<ReturnType<typeof getNotes>>>
export type GetNotesQueryError = ErrorType<WhimsyErrorResponseResponse>

export const useGetNotes = <TData = Awaited<ReturnType<typeof getNotes>>, TError = ErrorType<WhimsyErrorResponseResponse>>(
 userId: string,
    params?: GetNotesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotes>>, TError, TData>, }

  ):  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetNotesQueryKey(userId,params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotes>>> = ({ signal }) => getNotes(userId,params, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotes>>, TError, TData>(queryKey, queryFn, {enabled: !!(userId), ...queryOptions}) as  UseQueryReturnType<TData, TError, UseQueryResult<TData, TError>> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


